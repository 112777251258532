import React from "react";
import {IconButton, TextField, Persona} from 'office-ui-fabric-react';
import FieldCreator from './FieldCreator'
import {App} from "../index";

export class ArrayValueController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {draggingIndex: -1}
    }

    render() {
        const {items, onDelete, onEdit, onSwap, persona, onCopy, editingIndex, ...rest} = this.props;
        return (
            <div>
                {(items || []).map((item, index) => {
                    //console.log("item",item)
                    return (
                        <span draggable={true}
                              onDragStart={() => this.setState({draggingIndex: index})}
                              onDragOver={e => {
                                  if (this.state.draggingIndex != index) {
                                      onSwap(this.state.draggingIndex, index);
                                      this.setState({draggingIndex: index})
                                  }
                                  e.preventDefault()
                              }}
                              onDrop={() => this.setState({draggingIndex: -1})}
                              onDragEnd={() => this.setState({draggingIndex: -1})}
                              style={{
                                  margin: 2,
                                  display: "inline-block",
                                  //padding: 8,
                                  backgroundColor: index === editingIndex ? "#dadada" : "#f4f4f4",
                                  borderRadius: 10
                              }} key={index}>
                        <div className="iota-centered-item">
                             <span style={{float: "right"}}>
                                 <IconButton iconProps={{iconName: "Delete"}} onClick={() => onDelete(index)}/>
                            </span>
                            {persona ?
                                <Persona
                                    text={item.name}
                                    //secondaryText={user.name}
                                    imageUrl={App.REST_SERVER + "/attachments/download?id=" + item.pp_id}
                                    size={3}
                                    onClick={() => onEdit(index)}
                                    styles={{root: {margin: "8px 0"}}}
                                />
                                :
                                <span style={{height: "100%", padding: 8}} onClick={() => onEdit(index)}>
                                    {item.name}
                                </span>
                            }
                        </div>
             </span>
                    )
                })}
            </div>
        )
    }
}

class TextArray extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: "", editingIndex: -1}
        this.onKeyPressEnter = this.onKeyPressEnter.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSwap = this.onSwap.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCopy = this.onCopy.bind(this);
    }

    onCopy(index) {
        const {value, onChange} = this.props.input || this.props;
        onChange([...value, value[index]]);
    }

    onAdd(_value) {
        if (!_value || !_value.split(" ").join(""))
            return;
        const {value, onChange} = this.props.input || this.props;
        if (this.state.editingIndex > -1) {
            const newValue = [...value];
            newValue[this.state.editingIndex] = _value;
            onChange(newValue);
        } else {
            onChange([...value, _value]);
        }
        this.setState({value: "", editingIndex: -1})
    }

    onDelete(index) {
        const {value, onChange} = this.props.input || this.props;
        const newValue = [...value];
        newValue[index] = undefined;
        if (this.state.editingIndex > -1)
            this.setState({value: this.state.tempValue, editingIndex: -1});
        else
            this.setState({editingIndex: -1});
        onChange(newValue.filter(i => i))
    }

    onSwap(draggingIndex, droppingIndex) {
        const {value, onChange} = this.props.input || this.props;
        const newValue = [...value];
        const tempValue = newValue[draggingIndex];
        newValue[draggingIndex] = newValue[droppingIndex];
        newValue[droppingIndex] = tempValue
        onChange(newValue);
    }

    onEdit(index) {
        const {value} = this.props.input || this.props;
        if (this.state.editingIndex != index)
            this.setState({
                value: value[index],
                editingIndex: index,
                tempValue: this.state.editingIndex > -1 ? this.state.tempValue : this.state.value
            });
        else
            this.setState({value: this.state.tempValue, editingIndex: -1})
    }

    onChange(e) {
        this.setState({value: e})
    }

    onKeyPressEnter(e) {
        if (e.which === 13 || e.keyCode === 13)
            this.onAdd(this.state.value)
    }

    render() {
        const {value, onChange, ..._rest} = this.props.input || this.props;
        const {initalValue, meta, label, queryName, placeholder, queryParams, type, ...rest} = this.props;
        const errorMessage = meta ? meta.error : undefined;
        //console.log("fieldKey", this.props.label)
        return [
            <ArrayValueController key={"viewer"}
                                  items={value || initalValue}
                                  onEdit={this.onEdit}
                                  onSwap={this.onSwap}
                                  onCopy={this.onCopy}
                                  onDelete={this.onDelete}
                                  editingIndex={this.state.editingIndex}
            />,
            queryName ?
                <FieldCreator key={"selectfield"}
                              type={"selectfield"}
                              label={label}
                              queryName={queryName}
                              queryParams={queryParams}
                />
                :
                type === "longtext" ?
                    <TextField placeholder={placeholder}
                               multiline
                               key={"textarea"}
                               iconProps={{
                                   iconName: "Add",
                                   className: "ms-fontColor-themePrimary ms-bgColor-neutralLighter--hover",
                                   style: {
                                       position: "absolute",
                                       right: 0,
                                       padding: 8,
                                       bottom: 8,
                                       borderRadius: 16,
                                       fontSize: 20,
                                       cursor: "pointer"
                                   },
                                   onClick: () => this.onAdd(this.state.value)
                               }}
                               onBeforeChange={this.onChange}
                               label={label}
                               onBlur={e => this.onAdd(e.target.value)}
                               value={this.state.value}
                               errorMessage={errorMessage}
                               rows={4}
                    /> :
                    <TextField key={"textfield"}
                               onBeforeChange={this.onChange}
                               label={label}
                               placeholder={placeholder}
                               onBlur={e => this.onAdd(e.target.value)}
                               value={this.state.value}
                               onKeyPress={this.onKeyPressEnter}
                               errorMessage={errorMessage}/>
        ]
    }
};


export default TextArray