import * as actions from '../actions/index';
import { guid } from '../utils/utils';

export default function notification_reducer(state = [], action) {
    switch (action.type) {
        case `${actions.pushNotification}`:
            return [...state, { ...action.payload, key: guid() }];
        case `${actions.removeNotification}`:
            return state.filter(i => i.key !== action.payload.key);
        default:
            return state;
    }
}
