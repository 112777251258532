import React from 'react';
import LeftDrawer from './LeftDrawer';
import {CommandBarButton} from 'office-ui-fabric-react';
import {App} from '../../index';
import {connect} from 'react-redux'
import Sticky from 'react-sticky-el'

export default class Layout extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {open: false};
        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        this.setState({open: false, content: null, title: null})
    }

    render() {
        const {children} = this.props;

        return (
            <div className="layout-root">
                <LeftDrawer/>
                <main className="layout-content">
                    <AppBar/>
                    <div className="layout-content-children">
                        {children}
                    </div>

                </main>
            </div>
        );
    }
}

const AppBar = props => {
    return (
        <Sticky stickyStyle={{zIndex: 1}}>
            <div className={"app-bar iota-space-between"} style={{
                zIndex: 1,
                backgroundColor: "rgb(0,120,212)",
            }}>
                <Title/>
                <div className="iota-centered-item">
                    <Profile/>
                </div>
            </div>
        </Sticky>
    )
}

const _Title = props => {
    const {title} = props;
    return (
        <h2 className="ms-font-xxl ms-fontSize-xxl">{title || "Ana Sayfa"}</h2>
    )
}
const Title = connect(state => ({
    title: state.app && state.app.title
}))(_Title)

const _Profile = (props) => {

    function logout() {
        App.actions.logout({})
    }

    const {user} = props;
    if (!user)
        return <span/>;
    return (
        <CommandBarButton
            text={user.fullname}
            split={true}
            iconProps={{iconName: 'Contact', style: {color: "white"}}}
            style={{
                height: '35px',
                backgroundColor: "rgb(0,120,212)",
                color: "white",
            }}
            menuIconProps={{
                style: {
                    color: "white",
                }
            }}
            menuProps={{
                items: [
                    {
                        key: 'profile',
                        name: 'Profile',
                        iconProps: {iconName: 'Contact'},
                        onClick: () => App.history.push("/users/" + user.id)
                    },
                    {
                        key: 'logout',
                        name: 'Logout',
                        iconProps: {iconName: 'SignOut'},
                        onClick: logout
                    }
                ],
                style: {color: "white"}
            }
            }
        />

    );
}
const Profile = connect(state => ({
    user: state.auth && state.auth.userInfo
}))(_Profile)
