import React, {Component} from "react";
import FetchData from "../../components/FetchData";
import CardTitle from "../../components/CardTitle";
import ConnectedList from "../../components/ConnectedList";
import {
    Persona,
    CommandBar,
    Dialog,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    DialogType
} from "office-ui-fabric-react";
import DisplayItem from "../../components/DisplayItem";
import {App} from "../..";
import EmptyComponent from "../../components/EmptyComponent";
import StatusBar from "../../components-tm/StatusBar";
import RecordExtension from "../../components-tm/RecordExtension";
import DeleteDialog from "../../components-tm/DeleteDialog";
import Loading from "../../components/Loading";
import {dateToStr} from "../../utils/utils";
import ConnectedItem from "../../components/ConnectedItem";

class TaskDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {doneDialog: false};

        const {id, task_id, check_id} = props.match.params;

        App.actions.setTitle({title: "Check Detail"});

        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/projects",
                    label: "Project List"
                },
                {
                    path: "/projects/" + id,
                    label: "Project Detail"
                },
                {
                    path: "/projects/" + id + "/tasks/" + task_id,
                    label: "Task Detail"
                },
                {
                    path: "/projects/" + id + "/tasks/" + task_id + "/checks/" + check_id,
                    label: "Check Detail"
                }
            ]
        })
    }

    render() {
        const {id, task_id, check_id} = this.props.match.params;
        const cmd = item => [
            {
                key: "newItem",
                name: "Edit",
                iconProps: {
                    iconName: "Edit"
                },
                onClick: () =>
                    App.history.push(
                        "/projects/" + id + "/tasks/" + task_id + "/checks/" + check_id + "/edit"
                    )
            },
            {
                key: "delete",
                name: "Delete",
                iconProps: {
                    iconName: "Delete"
                },
                onClick: () => this.setState({deleteRecordId: check_id})
            },
            !item.status
                ? {
                    key: "done",
                    name: "Done",
                    iconProps: {
                        iconName: "Completed"
                    },
                    onClick: () => this.setState({doneDialog: true})
                }
                : null
        ].filter(i => i);

        return (
            <div className="tm-cell">
                <DoneDialog
                    open={this.state.doneDialog}
                    onClose={() => this.setState({doneDialog: false})}
                    id={check_id}
                />
                <DeleteDialog pk={{id: this.state.deleteRecordId}} open={this.state.deleteRecordId}
                              onClose={() => this.setState({deleteRecordId: false})}
                              deletePath={"checklists"}
                              onDelete={() => App.history.push("/projects/" + id + "/tasks/" + task_id)}
                />
                <ConnectedItem path={["auth", "userInfo"]}
                               component={p2 => {
                                   return (
                                       <ConnectedList
                                           path={["data", "checklists"]}
                                           component={p => {
                                               const items = p2.item.id === 1 ? cmd(p.item) : [];
                                               if (p.item.status || !items.length)
                                                   return null;
                                               return <CommandBar items={items}/>;
                                           }}
                                       />
                                   )
                               }}/>
                <div className="ms-Grid">
                    <FetchData tableName="checklists" params={{id: check_id}}/>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm8">
                            <ConnectedList
                                path={["data", "checklists"]}
                                component={p => {
                                    return (
                                        <div>
                                            <StatusBar status={p.item.status}/>
                                            <div className="tm-card" style={{padding: 16}}>
                                                <DisplayItem icon={"TextDocument"} label={"Title"}>
                                                    {p.item.title}
                                                </DisplayItem>
                                                <DisplayItem icon={"TextDocument"} label={"Description"}>
                                                    {p.item.dsc}
                                                </DisplayItem>
                                                <DisplayItem icon={"Calendar"} label={"Created Date"}>
                                                    {dateToStr(p.item.createdAt)}
                                                </DisplayItem>
                                                <DisplayItem icon={"DateTime"} label={"Finish Date"}>
                                                    {dateToStr(p.item.finish_date)}
                                                </DisplayItem>
                                                {p.item.done_date ?
                                                    <DisplayItem icon={"EventAccepted"} label={"Done Date"}>
                                                        {dateToStr(p.item.done_date)}
                                                    </DisplayItem> : null}
                                            </div>
                                        </div>
                                    );
                                }}
                                loadingComponent={
                                    () => {
                                        return (
                                            <div className={"tm-card"}>
                                                <Loading/>
                                            </div>
                                        )
                                    }
                                }
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4">
                            <div className="tm-card">
                                <ConnectedList
                                    path={["data", "checklists"]}
                                    component={p => {
                                        return (
                                            <div>
                                                <CardTitle icon={"Group"}>Collaborators</CardTitle>
                                                <div className="tm-cell">
                                                    {p.item.collabrators && p.item.collabrators.length ? (
                                                        p.item.collabrators.map((user, index) => {
                                                            return (
                                                                <Persona
                                                                    size={3}
                                                                    imageUrl={App.REST_SERVER + "/attachments/download?id=" + user.pp_id}
                                                                    text={user.name}
                                                                    secondaryText={user.username}
                                                                    key={index}
                                                                    styles={{root: {margin: "8px 0"}}}
                                                                />
                                                            );
                                                        })
                                                    ) : (
                                                        <EmptyComponent dsc="No Collaborator"/>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                    loadingComponent={
                                        () => {
                                            return (
                                                <div className={"tm-card"}>
                                                    <Loading/>
                                                </div>
                                            )
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"ms-Grid-row"}>
                        <div className="ms-Grid-col ms-sm12">
                            <RecordExtension parentType="checklists" parentId={check_id}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const DoneDialog = props => {
    const {open, onClose, id} = props;

    function onDone() {
        App.actions.upsertForm({
            formPath: "checklists",
            pk: {id},
            values: {
                status: 1,
                done_date: new Date()
            },
            onSave: i => {
                onClose();
            }
        });
    }

    return (
        <Dialog
            hidden={!open}
            onDismiss={onClose}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: "Are you sure?",
                subText:
                    "Are you sure to complete? This action can't undo!"
            }}
            modalProps={{
                isBlocking: false,
                containerClassName: "ms-dialogMainOverride"
            }}
        >
            <DialogFooter>
                <PrimaryButton onClick={onDone} text="Done"/>
                <DefaultButton onClick={onClose} text="Cancel"/>
            </DialogFooter>
        </Dialog>
    );
};

export default TaskDetail;
