import React, {Component} from "react";
import {CommandBar, SearchBox} from "office-ui-fabric-react";

class SearchCommandBar extends Component {
    render() {
        const {searchBox, commandBar} = this.props;
        return (
            <div style={{display: "flex", alignItems: "center", margin: "8px 16px"}}>
                <SearchBox placeholder="Search" {...searchBox} />
                <CommandBar {...commandBar} />
            </div>
        );
    }
}

export default SearchCommandBar;
