import React from 'react';
import ConnectedItem from './ConnectedItem'

const Protected = props => {
    const {extra} = props;
    return (
        <ConnectedItem path={["auth", "userInfo"]}
            component={p2 => {
                if (p2.item.id === 1 || !!(extra ? extra(p2.item) : true))
                    return props.children
                return null;
            }} />
    );
}

export default Protected;