import React, {Component} from "react";
import {
    Dialog,
    DialogFooter,
    DefaultButton,
    DialogType
} from "office-ui-fabric-react";
import {App} from "../index";

class DeleteDialog extends Component {
    constructor(props, context) {
        super(props, context);
        this.onDelete = this.onDelete.bind(this)
    }

    onDelete() {
        const {onDelete, pk, deletePath} = this.props;
        App.actions.deleteRecord({
            deletePath,
            pk,
            onDelete
        })
    }


    render() {
        const {onClose, open} = this.props;
        return (
            <Dialog
                hidden={!open}
                onDismiss={onClose}
                styles={{main: {maxWidth: "500px !important"}}}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Are you sure?",
                    styles: {header: {backgroundColor: "#e81123"}},
                    subText:
                        "Delete action can't undo! Do you want to delete anyway?"
                }}
                modalProps={{
                    isBlocking: false,
                    containerClassName: "ms-dialogMainOverride"
                }}
            >
                <DialogFooter>
                    <DefaultButton onClick={onClose} text="Cancel"/>
                    <DefaultButton className={"tm-delete-button"}
                                   onClick={this.onDelete}
                                   text="Delete"/>
                </DialogFooter>
            </Dialog>
        );
    }
}

export default DeleteDialog;