import React from "react";
import _ from "lodash";
import {Icon, ProgressIndicator} from "office-ui-fabric-react";
import Dropzone from "react-fine-uploader/dropzone";
import FileInput from "react-fine-uploader/file-input";
import Thumbnail from "react-fine-uploader/thumbnail";
import Filename from "react-fine-uploader/filename";
import Filesize from "react-fine-uploader/filesize";
import CancelButton from "react-fine-uploader/cancel-button";
import "react-fine-uploader/gallery/gallery.css";
import {connect} from "react-redux";
import {App} from "..";

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submittedFiles: []
        };
        //this.uploader = {};
        this.setUploader();
        App.actions.updateUploaderParams(this.props.params);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props != nextProps) {
            if (this.props.uploader != nextProps.uploader) {
                this.setUploader(nextProps);
            }
            if (!_.isEqual(this.props.params, nextProps.params)) {
                App.actions.updateUploaderParams(nextProps.params);
            }
        }
    }

    setUploader(nextProps) {
        const {uploader} = nextProps || this.props;
        if (!uploader) {
            console.error("not uplaoder");
            return;
        }
        this.uploader = uploader;
        this.uploader.on("onUpload", (id, name) => {
            if (this.props.onUpload) {
                this.props.onUpload(name);
            }
        });
        this.uploader.on("onSubmit", (id, name) => {
            if (this.props.onSubmitted) {
                this.props.onSubmitted(name);
            }
        });

        this.uploader.on("onComplete", (id, name, resp) => {
            if (this.props.onComplete) {
                this.props.onComplete(name, resp);
            }
        });

        this.uploader.on("onCancel", (id, name) => {
            if (this.props.onCancel) {
                this.props.onCancel(name);
            }
        });

        this.uploader.on("statusChange", (id, oldStatus, newStatus) => {
            if (newStatus === "submitted") {
                App.actions.submitFile({id});
            } else if (["canceled", "deleted"].indexOf(newStatus) > -1) {
                App.actions.deleteFile({id})
            } else if ("upload successful" === newStatus) {
                App.actions.deleteFile({id})
            }
        });
    }

    render() {
        const {submittedFiles} = this.props
        if (!this.uploader) return null;
        return (
            <div className="iota-fileupload-card">
                <Dropzone
                    style={{height: 150, margin: 8}}
                    className={"iota-centered-item"}
                    multiple
                    uploader={this.uploader}
                >
                    <div style={{textAlign: "center"}}>
                        <p className="ms-font-xl" style={{margin: 0}}>
                            Dosyaları Buraya Sürükleyin veya
                        </p>
                        <FileInput
                            uploader={this.uploader}
                            multiple={true}
                            onSubmit={(a, b, c) => console.log("input", a, b, c)}
                        >
                            <p className="ms-font-xl" style={{color: "#3f51b5", margin: 0}}>
                                Buradan Seçin
                            </p>
                        </FileInput>
                    </div>
                </Dropzone>

                {submittedFiles.length ? (
                    <Files/>
                ) : null}
            </div>
        );
    }
}

class _Files extends React.Component {
    render() {
        const {submittedFiles, uploader} = this.props;
        if (!submittedFiles || !submittedFiles.length) {
            return null;
        }
        return (
            <div style={{width: "100%", height: "100%", padding: 8}}>
                <div className={"ms-Grid"}>
                    <div className={"ms-Grid-row"}>
                    {submittedFiles.map((id, index) => {
                        if (id === false) return null;
                        return (
                            <div
                                key={index}
                                className="ms-Grid-col ms-sm12 ms-md6 ms-lg4"
                                style={{textAlign: "center", position: "relative"}}
                            >
                                <div className={"iota-centered-item"}>
                                    <div>
                                        <div className={"iota-three-dot"}>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    width: 128,
                                                    margin: "0 auto"
                                                }}
                                                className={"iota-centered-item"}
                                            >
                                                <Thumbnail id={id} uploader={uploader}/>
                                                <CancelButton id={id} uploader={uploader}>
                                                    <Icon
                                                        style={{
                                                            position: "absolute",
                                                            top: 0,
                                                            right: 0,
                                                            padding: 4,
                                                            color: "white",
                                                            borderRadius: "50%",
                                                            backgroundColor: "#212121"
                                                        }}
                                                        iconName="Cancel"
                                                    />
                                                </CancelButton>
                                            </div>
                                        </div>
                                        <ProgressBar
                                            id={id}
                                            uploader={uploader}
                                            label={
                                                <Filename
                                                    id={id}
                                                    className={"iota-three-dot ms-font-s"}
                                                    uploader={uploader}
                                                />
                                            }
                                            dsc={
                                                <Filesize
                                                    id={id}
                                                    className="ms-font-xs"
                                                    uploader={uploader}
                                                />
                                            }
                                            className="react-fine-uploader-gallery-total-progress-bar"
                                            hideBeforeStart={false}
                                            style={{margin: "0 auto", position: "static"}}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                </div>
                <div style={{width: "calc(100% - 16px)", height: 50}}>
                    <ProgressBar
                        uploader={uploader}
                        className="react-fine-uploader-gallery-total-progress-bar"
                    />
                </div>
            </div>
        );
    }
}

export const Files = connect(state => ({
    uploader: state.uploader,
    submittedFiles: state.submittedFiles
}))(_Files);

class ProgressBar extends React.Component {
    static defaultProps = {
        hideBeforeStart: true,
        hideOnComplete: true
    };

    constructor(props) {
        super(props);

        this.state = {
            bytesUploaded: null,
            hidden: props.hideBeforeStart,
            totalSize: null
        };

        this._createEventHandlers();
    }

    componentDidMount() {
        if (this._isTotalProgress) {
            this.props.uploader.on("totalProgress", this._trackProgressEventHandler);
        } else {
            this.props.uploader.on("progress", this._trackProgressEventHandler);
        }

        this.props.uploader.on("statusChange", this._trackStatusEventHandler);
    }

    componentWillUnmount() {
        this._unmounted = true;
        this._unregisterEventHandlers();
    }

    render() {
        const {label, dsc} = this.props;
        const percentWidth = this.state.bytesUploaded / this.state.totalSize || 0;
        return (
            <ProgressIndicator
                label={label || ""}
                description={dsc || ""}
                percentComplete={percentWidth}
            />
        );
    }

    _createEventHandlers() {
        if (this._isTotalProgress) {
            this._trackProgressEventHandler = (bytesUploaded, totalSize) => {
                this.setState({bytesUploaded, totalSize});
            };
        } else {
            this._trackProgressEventHandler = (
                id,
                name,
                bytesUploaded,
                totalSize
            ) => {
                if (id === this.props.id) {
                    this.setState({bytesUploaded, totalSize});
                }
            };
        }

        const statusEnum = this.props.uploader.qq.status;

        this._trackStatusEventHandler = (id, oldStatus, newStatus) => {
            if (!this._unmounted) {
                if (this._isTotalProgress) {
                    if (
                        !this.state.hidden &&
                        this.props.hideOnComplete &&
                        isUploadComplete(newStatus, statusEnum) &&
                        !this.props.uploader.methods.getInProgress()
                    ) {
                        this.setState({hidden: true});
                    } else if (
                        this.state.hidden &&
                        this.props.uploader.methods.getInProgress()
                    ) {
                        this.setState({hidden: false});
                    }
                } else if (id === this.props.id) {
                    if (this.state.hidden && newStatus === statusEnum.UPLOADING) {
                        this.setState({hidden: false});
                    } else if (
                        !this.state.hidden &&
                        this.props.hideOnComplete &&
                        isUploadComplete(newStatus, statusEnum)
                    ) {
                        this.setState({hidden: true});
                    }
                }
            }
        };
    }

    get _isTotalProgress() {
        return this.props.id === null;
    }

    _unregisterEventHandlers() {
        if (this._isTotalProgress) {
            this.props.uploader.off("totalProgress", this._trackProgressEventHandler);
        } else {
            this.props.uploader.off("progress", this._trackProgressEventHandler);
        }

        this.props.uploader.off("statusChange", this._trackStatusEventHandler);
    }
}

const isUploadComplete = (statusToCheck, statusEnum) =>
    statusToCheck === statusEnum.UPLOAD_FAILED ||
    statusToCheck === statusEnum.UPLOAD_SUCCESSFUL ||
    statusToCheck === statusEnum.CANCELED;

export default connect(state => ({
    uploader: state.uploader,
    submittedFiles: state.submittedFiles
}))(FileUploader);
