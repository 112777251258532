import React, {Component} from "react";
import FieldCreator from "../../components/FieldCreator";
import {reduxForm} from "redux-form";
import {Button, PrimaryButton} from "office-ui-fabric-react";
import {App} from "../..";
import FormLoader from "../../components-tm/FormLoader";

class CheckEdit extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.onSave = this.onSave.bind(this);

        const {id, task_id, check_id} = props.match.params;

        const editPaths = check_id ?
            [{
                path: "/projects/" + id + "/tasks/" + task_id + "/checks/" + check_id,
                label: "Check Detail"
            },
                {
                    path: "/projects/" + id + "/task/" + task_id + "/checks/" + check_id + "/edit",
                    label: "Check Edit"
                }] :
            [{
                path: "/projects/" + id + "/task/" + task_id + "/newCheck",
                label: "New Check"
            }]

        App.actions.setTitle({title: "Check Edit"});

        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/projects",
                    label: "Project List"
                },
                {
                    path: "/projects/" + id,
                    label: "Project Detail"
                },
                {
                    path: "/projects/" + id + "/tasks/" + task_id,
                    label: "Task Detail"
                },
                ...editPaths
            ]
        })

    }

    onSave() {
        const {id, task_id, check_id} = this.props.match.params;
        App.actions.upsertForm({
            formName: "check-edit",
            formPath: "checklists",
            pk: check_id ? {id: check_id} : undefined,
            onSave: (check) => check_id ? App.history.goBack() : App.history.push("/projects/" + id + "/tasks/" + task_id + "/checks/" + check.id)
        });
    }

    render() {
        const {id, task_id, check_id} = this.props.match.params;
        return (
            <div className="tm-card tm-cell">
                <FormLoader
                    formName="check-edit"
                    queryPath={"checklists"}
                    params={{id: check_id}}
                    isNew={!check_id}
                    formInitialData={{task_id, status: 0}}
                />
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Title" name="title"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Description" name="dsc"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="date" label="Finish Date" name="finish_date"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator
                        type="persona"
                        queryPath="tasks/collabrators"
                        params={{id: task_id}}
                        valField="username"
                        insertItem
                        isArray
                        textField="name"
                        label="Collaborators"
                        name="collabrators"
                    />
                </div>
                <span className="tm-cell">
          <Button onClick={() => App.history.goBack()}>Decline</Button>
        </span>
                <span className="tm-cell">
          <PrimaryButton onClick={this.onSave}>Save</PrimaryButton>
        </span>
            </div>
        );
    }
}

export default reduxForm({form: "check-edit"})(CheckEdit);
