'use strict'
import {take, fork, call} from 'redux-saga/effects';
import * as actions from '../actions/auth';
import * as api from './api';

function* execLogout(action, userName) {
    yield call(api.logout, userName);
}

function* logout(state) {
    while (true) {
        const action = yield take(`${actions.logout}`);
        const {auth: {userName}} = state()
        yield fork(execLogout, action, userName);
    }
}

function* execLogin(action) {

    const {payload} = action
    yield call(api.login, payload);
}

function* login() {
    while (true) {
        const action = yield take(`${actions.login}`);
        yield fork(execLogin, action);
    }
}

function* execfetchUserInfo(action, auth) {
    yield call(api.fetchUserInfo, auth);
}

function* fetchUserInfo() {
    while (true) {
        const action = yield take(`${actions.fetchUserInfo}`);
        yield fork(execfetchUserInfo, action);
    }
}

export default function* rootSaga(getState) {
    yield fork(logout, getState);
    yield fork(login, getState);
    yield fork(fetchUserInfo, getState);
}