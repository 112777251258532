import React from "react";
import {
    List,
    Icon,
    DirectionalHint,
    CommandBarButton,
    TooltipHost,
    OverflowSet,
    CommandBar,
    SearchBox
} from "office-ui-fabric-react";
import Loading from "../../components/Loading";
import {dateToStr} from "../../utils/utils";
import EmptyComponent from "../../components/EmptyComponent";
import FileUploader from "../../components/FileUploader";
import {Files} from "../../components/FileUploader";
import FileInput from "react-fine-uploader/file-input";
import {connect} from "react-redux";
import FetchData from "../../components/FetchData";
import {App} from "../..";
import DeleteDialog from "../DeleteDialog";

class Attachments extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {items: undefined};
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDragEnter(e) {
        this.setState({dragging: true});
    }

    onDragLeave(e) {
        this.setState({dragging: false});
    }

    onDragEnd(e) {
        this.setState({dragging: false});
    }

    render() {
        const {parentType, parentId} = this.props;
        return (
            <div
                onDragOver={this.onDragOver}
                onDragEnter={this.onDragEnter}
                className={"attachment"}
            >
                <FetchData
                    tableName={"attachments"}
                    params={{parent_id: parentId, parent_type: parentType}}
                />
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "3px solid #f4f4f4"
                    }}
                >
                    <div style={{minWidth: 200, backgroundColor: "white"}}>
                        <SearchBox
                            placeholder="Search..."
                            className="iota-borderless"
                            onChange={val => {
                                this.setState({search: val})
                            }}
                        />
                    </div>
                    <CommandBar
                        className={"iota-fullwidth"}
                        style={{width: "100% !important"}}
                        elipisisAriaLabel="More options"
                        items={[
                            {
                                key: "download",
                                icon: "CloudUpload",
                                name: "Upload",
                                onRender: UploadButton,
                                onClick: () => {
                                    return;
                                }
                            },
                            {
                                key: "preview",
                                iconProps: {iconName: "Refresh"},
                                name: "Refresh",
                                onClick: () => {
                                    App.actions.reloadQuery({queryName: "attachments"})
                                }
                            }
                        ]}
                    />
                </div>
                <div>
                    <div
                        style={{
                            height: this.state.dragging ? 150 : 0,
                            transition: "0.4s",
                            overflow: "hidden"
                        }}
                    >
                        <FileUploader
                            key={"file-upload"}
                            params={{parentId, parentType}}
                        />
                    </div>
                    <Files/>
                    <FileList search={this.state.search}/>
                </div>
            </div>
        );
    }
}

const _FileList = props => {
    const {files: _files, search} = props;
    const files = (_files || []).filter(i => !search || !i.file_name || i.file_name.toLowerCase().indexOf(search.toLowerCase()) > -1)
    return _files ? (
        files.length ? (
                <div>
                    <List
                        items={files}
                        onRenderCell={(e) => React.createElement(ListItem, e)}
                        style={{maxHeight: 500, overflow: "auto"}}
                    />
                </div>
            ) :
            search && search.length ?
                <EmptyComponent dsc={'"' + search + '"' + " Not Found"}/>
                :
                (
                    <EmptyComponent dsc={"No Attachment"}/>
                )
    ) : (
        <Loading/>
    );
};

const FileList = connect(state => ({
    files: state.data && state.data.attachments
}))(_FileList);

function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const UploadButton = props => {
    return (
        <div>
            <FileInputUploader {...props} />
        </div>
    );
};

const _FileInputUploader = props => {
    const {icon, name, onClick, uploader} = props;
    if (!uploader)
        return null;

    return (
        <FileInput multiple uploader={uploader}>
            <CommandBarButton
                styles={{root: {padding: "10px"}}}
                iconProps={{iconName: icon}}
                onClick={onClick}
                text={name}
            />
        </FileInput>
    );
};

const FileInputUploader = connect(state => ({
    uploader: state.uploader
}))(_FileInputUploader);

class ListItem extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {deleteRecordId: false};
    }


    render() {
        const {id, file_name, file_size, user_full_name, createdAt} = this.props;
        return (
            <div className="iota-attachment-list-item">
                {this.state.deleteRecordId ?
                    <DeleteDialog open={this.state.deleteRecordId}
                                  deletePath={"attachments"}
                                  pk={{id}}
                                  onDelete={() =>
                                      this.setState({deleteRecordId: false},
                                          () => App.actions.reloadQuery({queryName: "attachments"}))}
                    /> : null}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        padding: "1px 0"
                    }}
                    className="iota-attachment-list-item-content-wrapper"
                >
                    <Icon
                        style={{fontSize: 24, padding: 16, margin: 16}}
                        iconName={"Cloud"}
                    />
                    <div style={{padding: "8px 0"}}>
                        <p
                            className="ms-fontSize-m ms-font-m-plus"
                            style={{margin: "8px 0"}}
                        >
                            {file_name}
                        </p>
                        <p className="ms-fontSize-s ms-font-m" style={{margin: 0}}>
                            {bytesToSize(file_size)}
                        </p>
                        <p className="ms-fontSize-sPlus ms-font-s-plus">
            <span className="ms-fontSize-m ms-font-s-plus ms-fontWeight-semibold">
              {user_full_name || "Murat Güney"}
            </span>{" "}
                            tarafından{" "}
                            <span className="ms-fontSize-m ms-font-s-plus ms-fontWeight-semibold">
              {" "}
                                {dateToStr(
                                    createdAt,
                                    "DD MMMM YYYY [tarihinde saat] HH:mm"
                                )}'{" "}
            </span>
                            de {user_full_name === "Sistem" ? "oluşturuldu." : "eklendi."}
                        </p>
                    </div>
                </div>
                <OverflowSet
                    className="iota-attachment-list-item-buttons"
                    vertical
                    items={[
                        {
                            key: "download",
                            icon: "CloudDownload",
                            name: "İndir",
                            ariaLabel: "İndir",
                            onClick: () => {
                                const a = document.createElement("a");
                                a.download = true;
                                a.target = "blank";
                                a.href = App.REST_SERVER + "/attachments/download?id=" + id;
                                a.click();
                            }
                        },
                        {
                            key: "delete",
                            icon: "Delete",
                            name: "Sil",
                            style: {color: "red"},
                            onClick: () => {
                                this.setState({deleteRecordId: id})
                            }
                        }
                    ]}
                    onRenderItem={ItemRenderer}
                />
            </div>
        )
            ;
    };
};

const ItemRenderer = props => {
    const {icon, name, onClick, style} = props;
    return (
        <TooltipHost
            content={name}
            calloutProps={{
                directionalHint: DirectionalHint.rightCenter,
                beakWidth: 12
            }}
        >
            <CommandBarButton
                styles={{root: {padding: "10px"}}}
                iconProps={{iconName: icon, style}}
                onClick={onClick}
            />
        </TooltipHost>
    );
};

export default Attachments;
