import React from 'react';
import {Label, Spinner, SpinnerSize} from "office-ui-fabric-react";
import EmptyComponent from "./EmptyComponent";

const Loading = props => {
    const {label, spinnerLabel} = props;
    return (
        <EmptyComponent style={{width: "100%", fontSize: 15, minHeight: 216}} dsc={
            <div>
                <Label>{label || "Yükleniyor"}</Label>
                <Spinner size={SpinnerSize.large}
                         label={spinnerLabel || 'Kayıtlar Yükleniyor'}
                         ariaLive='assertive'/>
            </div>
        }/>
    )
}

export default Loading;