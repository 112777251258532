import React, {Component} from 'react';
import Login from './Login';

class LoginPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {expand: [], page: "login", params: {}};
        this.contentController = this.contentController.bind(this);
        this.getParams = this.getParams.bind(this);
    }

    componentDidMount() {
        this.getParams()
    }

    getParams() {
        const search = this.props.location.search;
        if (search) {
            let queryString = search.split("?")[1];
            const params = {};
            if (queryString) {
                queryString.split("&").map(string => {
                    params[string.split("=")[0]] = string.split("=")[1]
                });
                this.setState({params, page: "register"})
            }
        }
    }

    contentController(index) {
        const expand = [...this.state.expand];
        expand[index] = !expand[index];
        this.setState({expand})
    }

    render() {
        return (
            <div className="login-background iota-centered-item">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9 ">
                    <div style={{minHeight: 400, backgroundColor: "white", position: "relative"}}>
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{height: 370}}>
                                    <div style={{padding: 32, backgroundColor: "rgb(0, 120, 212)", color: "white"}}>
                                        <p className="ms-fontSize-xxl ms-font-xl">Login</p>
                                    </div>
                                    <div style={{padding: 32}}>
                                        <Login params={this.state.params}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;
