import format from "date-fns/format";
import englishLocale from "date-fns/locale/en";

export const guid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
});

export function dateToStr(date, ff = 'DD MMMM YYYY') {
    if (!date)
        return '';
    return format(date, ff, {locale: englishLocale});
}

export function hash(str) {
    const string = str.toString();
    let hash = 0, i;
    for (i = 0; i < string.length; i++)
        hash = (((hash << 5) - hash) + string.charCodeAt(i)) & 0xFFFFFFFF;
    return hash;
}

export function lightenDarkenColor(col, amt) {

    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}