/* eslint react/prop-types: 0 */
import React from 'react';
import {TextField} from 'office-ui-fabric-react';
import {Toggle as Switch} from 'office-ui-fabric-react';
import {DatePicker, DayOfWeek} from 'office-ui-fabric-react';
import {Field} from 'redux-form';
import {Dropdown} from 'office-ui-fabric-react';
import {Checkbox, CommandBar, CommandButton} from 'office-ui-fabric-react';
import {ComboBox, ProgressIndicator} from 'office-ui-fabric-react';
import _TextArray, {ArrayValueController} from './TextArray'
import _JsonArray from './JsonArray'
import {connect} from 'react-redux'
import FetchData from './FetchData';

class FieldCreator extends React.Component {
    render() {
        const {type, name, ...rest} = this.props;
        const component = (name ? fieldsJSON : elementJSON)[type];
        if (component)
            return (
                React.createElement(component, {name, type, ...rest})
            )
        else
            return <p>Desteklenmeyen field ( {name} ) ( {type} )</p>
    }
}

const Textfield = props => {
    const {name, ...rest} = props;
    return (
        <Field name={name} {...rest} component={rest.isArray ? _TextArray : _Textfield}/>
    )
};

const JsonArray = props => {
    const {name, ...rest} = props;
    return (
        <Field name={name} {...rest} component={_JsonArray}/>
    )
};

const Textarea = props => {
    const {name, ...rest} = props;
    return (
        <Field name={name} {...rest} component={rest.isArray ? _TextArray : _Textarea}/>
    )
};

const Datepicker = props => {
    const {name, ...rest} = props;
    return (
        <Field name={name} {...rest} component={_Datepicker}/>
    )
};

const Combobox = props => {
    const {name, ...rest} = props;
    return (
        <Field name={name} {...rest} component={_Combobox}/>
    )
};

const Switcher = props => {
    const {name, ...rest} = props;

    return (
        <Field name={name} {...rest} component={_Switcher}/>
    )
};

const CheckBox = props => {
    const {name, ...rest} = props;

    return (
        <Field name={name} {...rest} component={_CheckBox}/>
    )
};

const PersonaArray = props => {
    const {name, ...rest} = props;

    return (
        <Field name={name} {...rest} component={_PersonaArray}/>
    )
};

const NumberField = props => {
    const {name, ...rest} = props;

    return (
        <Field name={name} {...rest} component={_NumberField}/>
    )
};

const _NumberField = props => {
    const {value, onChange, ..._rest} = props.input || props;
    const {initalValue, ...rest} = props;
    return (
        <TextField onChanged={onChange}
                   value={value || initalValue}
                   {...rest}
                   {..._rest}
        />
    )
};

const _Textfield = props => {
    const {value, onChange, ..._rest} = props.input || props;
    const {initalValue, meta, ...rest} = props;
    const errorMessage = meta ? meta.error : undefined;
    return (
        <TextField onChanged={onChange} value={value || initalValue} {...rest} {..._rest} errorMessage={errorMessage}/>
    )
};

const _Textarea = props => {
    const {value, onChange, ..._rest} = props.input || props;
    const {initalValue, ...rest} = props;
    return (
        <TextField
            multiline
            fullWidth
            rows="4"
            onChanged={onChange}
            {...rest}
            {..._rest}
            value={value}
        />
    )
};

const _Datepicker = props => {
    const {value, onChange, required, ..._rest} = props.input || props;
    const {initalValue, meta, ...rest} = props;
    const errorMessage = meta ? meta.error : undefined;
    return (
        <DatePicker firstDayOfWeek={DayOfWeek.Monday}
                    strings={DayPickerStrings}
                    placeholder='Select a date..'
                    label={rest.label}
                    {...rest}
                    {..._rest}
                    isRequired={required}
                    isRequiredErrorMessage={errorMessage}
                    value={value ? new Date(value) : null}
                    onSelectDate={onChange}
        />
    )
};

const _Switcher = props => {
    const {value, onChange, ..._rest} = props.input || props;
    const {initalValue, trueValue, falseValue, valueParser, ...rest} = props;
    return (
        <Switch checked={trueValue === value}
                defaultChecked={trueValue === value}
                {...rest}
                {..._rest}
                onChange={(e, check) => onChange(check ? trueValue === undefined ? "1" : trueValue : falseValue === undefined ? "0" : falseValue)}/>

    )
};

const _CheckBox = props => {
    const {value, onChange, ..._rest} = props.input || props;
    const {initalValue, trueValue, falseValue, ...rest} = props;
    return (
        <Checkbox value={value ? parseInt(value) : undefined}
                  checked={value ? parseInt(value) : undefined}
                  defaultChecked={value ? parseInt(value) : undefined}
                  {...rest}
                  {..._rest}
                  onChange={(event, check) => onChange(check ? trueValue === undefined ? "1" : trueValue : falseValue === undefined ? "0" : falseValue)}/>

    )
}

class __PersonaArray extends React.Component {

    constructor(props) {
        super(props);
        this.state = {value: "", editingIndex: -1}
        this.onKeyPressEnter = this.onKeyPressEnter.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSwap = this.onSwap.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCopy = this.onCopy.bind(this);
    }

    onCopy(index) {
        const {value, onChange} = this.props.input || this.props;
        onChange([...value, value[index]]);
    }

    onAdd(_value) {
        if (!_value)
            return;
        const {value, onChange} = this.props.input || this.props;
        if (this.state.editingIndex > -1) {
            const newValue = [...value];
            newValue[this.state.editingIndex] = _value;
            onChange(newValue);
        } else {
            onChange([...value, _value]);
        }
        this.setState({value: "", editingIndex: -1})
    }

    onDelete(index) {
        const {value, onChange} = this.props.input || this.props;
        const newValue = [...value];
        newValue[index] = undefined;
        if (this.state.editingIndex > -1)
            this.setState({value: this.state.tempValue, editingIndex: -1});
        else
            this.setState({editingIndex: -1});
        onChange(newValue.filter(i => i))
    }

    onSwap(draggingIndex, droppingIndex) {
        const {value, onChange} = this.props.input || this.props;
        const newValue = [...value];
        const tempValue = newValue[draggingIndex];
        newValue[draggingIndex] = newValue[droppingIndex];
        newValue[droppingIndex] = tempValue
        onChange(newValue);
    }

    onEdit(index) {
        const {value} = this.props.input || this.props;
        if (this.state.editingIndex != index)
            this.setState({
                value: value[index],
                editingIndex: index,
                tempValue: this.state.editingIndex > -1 ? this.state.tempValue : this.state.value
            });
        else
            this.setState({value: this.state.tempValue, editingIndex: -1})
    }

    onKeyPressEnter(e) {
        if (e.which === 13 || e.keyCode === 13)
            this.onAdd(this.state.value)
    }

    onChange(event, item, index) {
        const {value, onChange, ..._rest} = this.props.input || this.props;
        const {isArray, insertKey, valField, textField, items, insertItem} = this.props;
        if (isArray) {
            if (item.selected) {
                const val = !!insertItem ? item : !!insertKey ? item.key : {
                    [valField]: item.key,
                    [textField]: item.text
                }
                this.onAdd(val)
            } else {
                const _index = value.map(i => i[valField]).indexOf(item.key);
                this.onDelete(_index)
            }
        } else {
            onChange(!!insertItem ? item : !!insertKey ? item.key : {[valField]: item.key, [textField]: item.text})
        }
    }

    render() {
        const {value, onChange, ..._rest} = this.props.input || this.props;
        const {queryName, queryParams, queryPath, insertItem, params, alias, load, insertKey, valField: _valField, textField: _textField, items, selectfieldoptions, initalValue, isArray, ...rest} = this.props;
        const valField = _valField || "val";
        const textField = _textField || "dsc";
        return [
            isArray ? <CommandBar
                key={"commandbar"}
                className="iota-fullwidth"
                style={{width: "100% !important"}}
                items={
                    [
                        {
                            label: "Select All",
                            onClick: () => onChange([...items.map(i => insertKey ? i[valField] : i)]),
                            icon: "CheckList",
                            onRender: ItemRenderer,
                            key: "selectall"
                        },
                        {
                            label: "Clear All",
                            onClick: () => onChange([]),
                            icon: "Delete",
                            onRender: ItemRenderer,
                            key: "deletetall"
                        },
                    ]
                }
            /> : null,
            queryPath ? <FetchData tableName={queryPath} key={"query"} params={params}/> : null,
            isArray ? <ArrayValueController key={"viewer"}
                                            items={(value || []).map(i => insertKey ? items.filter(i2 => i2[valField] === i)[0] : i)}
                                            onEdit={this.onEdit}
                                            onSwap={this.onSwap}
                                            persona
                                            onCopy={this.onCopy}
                                            onDelete={this.onDelete}
                                            editingIndex={this.state.editingIndex}
            /> : null,
            (queryPath ? load != undefined : items) ? items.length ? <Dropdown
                    {...rest}
                    {..._rest}
                    options={(items || []).map((item) => ({
                        key: item[valField],
                        text: item[textField],
                        ...(insertItem ? item : {})
                    }))}
                    key={"selectfield"}
                    selectedKeys={isArray ? (value || []).map(i => insertKey ? i : i[valField]) : false}
                    multiSelect={isArray}
                    selectedKey={isArray ? false : !!insertKey ? value : value[valField]}
                    onChange={this.onChange}
                /> : <FieldCreator key={"empty"} type={"shorttext"} disabled value={"Kayıt Bulunmamaktadır"}
                                   label={rest.label}/> :
                <ProgressIndicator key={"progress"} label={rest.label} description={"Yükleniyor..."}/>
        ]
    }
};

const _PersonaArray = connect((state, ownProps) => ({
    items: state.data && state.data[ownProps.queryPath],
    load: state.data && state.data[ownProps.queryPath] && state.data[ownProps.queryPath].length
}))(__PersonaArray);



const ItemRenderer = function (props) {
    return <CommandButton text={props.label}
                          key={props.key}
                          disabled={!!props.disabled}
                          iconProps={{iconName: props.icon}}
                          onClick={props.onClick}
                          menuProps={props.subMenuProps}/>
};
const _Combobox = props => {
    const {value, onChange, ..._rest} = props.input || props;
    const {initalValue, ...rest} = props;

    return (
        <ComboBox
            {...rest}
            {..._rest}
            onChanged={onChange}
        />
    )
}

const fieldsJSON = {
    shorttext: Textfield,
    longtext: Textarea,
    date: Datepicker,
    switch: Switcher,
    toggle: Switcher,
    checkbox: CheckBox,
    password: Textfield,
    combobox: Combobox,
    persona: PersonaArray,
    number: NumberField,
    multifield: JsonArray,
    hiddenfield: () => null
};

export const elementJSON = {
    shorttext: _Textfield,
    longtext: _Textarea,
    switch: _Switcher,
    combobox: _Combobox,
    password: _Textfield,
    date: _Datepicker,
    checkbox: _CheckBox,
    number: _NumberField,
    textlistfield: _TextArray,
    jsonArray: _JsonArray
};

const DayPickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],

    shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ],

    days: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
    ],

    shortDays: [
        'M',
        'T',
        'W',
        'T',
        'F',
        'S',
        'S'
    ],

    goToToday: 'Today',
    prevMonthAriaLabel: 'Previous Month',
    nextMonthAriaLabel: 'Next Ay',
    prevYearAriaLabel: 'Previous Year',
    nextYearAriaLabel: 'Next Year'
};

export default FieldCreator;