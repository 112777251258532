import React from "react";
import PropTypes from "prop-types";

const EmptyComponent = props => {
    const {dsc, className, style, cardTextStyle, contentClassName, ...rest} = props;
    return (
        <div {...rest} className={className + " iota-centered-item"}
             style={{
                 lineHeight: 1.5,
                 minHeight: 150,
                 width: "auto",
                 height: "100%",
                 ...cardTextStyle
             }}>
            <div style={{
                height: "100%",
                textAlign: "center",
                margin: 0,
                ...style
            }} className={"iota-centered-item ms-fontSize-xl ms-font-xl " + contentClassName}
            >{dsc}</div>
        </div>
    )
};


EmptyComponent.propTypes = {
    dsc: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    cardTextStyle: PropTypes.object
};

export default EmptyComponent