import createAction from 'redux-act/lib/createAction';

export const setUploader = createAction('setUploader');
export const removeNotification = createAction('removeNotification');
export const pushNotification = createAction('pushNotification');
export const updateUploaderParams = createAction('updateUploaderParams');
export const fetchData = createAction('fetchData');
export const clearData = createAction('clearData');
export const upsertForm = createAction('upsertForm');
export const fetchDataSuccess = createAction('fetchDataSuccess');
export const setTitle = createAction('setTitle');
export const deleteRecord = createAction('deleteRecord');
export const reloadQuery = createAction('reloadQuery');
export const setBreadcrumb = createAction('setBreadcrumb');
export const addBreadcrumb = createAction('addBreadcrumb');