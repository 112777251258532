import React, { Component } from "react";
import { Icon } from "office-ui-fabric-react";

class DisplayItem extends Component {
  render() {
    const { icon, children, label, ...rest } = this.props;
    return (
      <div style={{ display: "flex" }} {...rest}>
        <div className="ms-font-xl-plus iota-cell">
          <Icon iconName={icon} style={{ color: "rgb(16, 110, 190)" }} />
        </div>
        <div
          className={"iota-cell"}
          style={{ marginTop: 0, width: "100%", overflow: "hidden" }}
        >
          <p
            className="ms-fontWeight-semibold ms-font-xl ms-fontSize-l"
            style={{ margin: 0 }}
          >
            {label}
          </p>
          <div
            style={{ marginBlockStart: "1em", marginBlockEnd: "1em" }}
            className="ms-font-m ms-fontSize-m"
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayItem;
