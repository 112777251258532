import React from "react";
import { App } from "../index";

class FetchData extends React.Component {
  componentWillMount() {
    App.actions.fetchData(this.props);
  }

  componentWillUnmount() {
    App.actions.clearData(this.props);
  }

  render() {
    return null;
  }
}

export default FetchData;
