import React, {Component} from "react";
import FetchData from "../../components/FetchData";
import ConnectedList from "../../components/ConnectedList";
import {
    DocumentCard,
    DocumentCardTitle,
    DocumentCardStatus,
    DocumentCardActivity
} from "office-ui-fabric-react";
import {dateToStr} from "../../utils/utils";
import {App} from "../..";
import SearchCommandBar from "../../components-tm/SearchCommandBar";
import EmptyComponent from "../../components/EmptyComponent";
import ConnectedItem from "../../components/ConnectedItem";

class ProjectList extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {search: ""};
        App.actions.setTitle({title: "Project List"});
        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/projects",
                    label: "Project List"
                }
            ]
        })
    }

    render() {
        const cmd = [
            {
                key: "newItem",
                name: "New",
                iconProps: {
                    iconName: "Add"
                },
                onClick: () => App.history.push("/projects/new")
            }
        ];
        return (
            <div>
                <FetchData tableName="projects"/>
                <ConnectedItem path={["auth", "userInfo"]}
                               component={p => {
                                   const items = p.item.id === 1 ? cmd : [];
                                   return (
                                       <SearchCommandBar commandBar={{items}} searchBox={{
                                           onChange: e => this.setState({search: e})
                                       }}/>
                                   )
                               }}/>

                <div className="ms-Grid">
                    <ConnectedList
                        customFilter={item => !this.state.search || (item.title.toLowerCase() + item.dsc.toLowerCase()).indexOf(this.state.search.toLowerCase()) > -1}
                        path={["data", "projects"]}
                        component={p => {
                            return (
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                    <DocumentCard
                                        className="tm-cell cursor-pointer-shadow tm-document-card"
                                        styles={{root: {maxWidth: "auto"}}}
                                        onClick={() => App.history.push("/projects/" + p.item.id)}
                                    >
                                        <div>
                                            <DocumentCardTitle
                                                title={p.item.title}
                                                shouldTruncate={true}
                                            />
                                            <DocumentCardTitle
                                                title={p.item.dsc}
                                                shouldTruncate={true}
                                                showAsSecondaryTitle={true}
                                            />
                                            <DocumentCardStatus
                                                statusIcon="AwayStatus"
                                                status={p.item.status ? "Completed" : "On Progress"}
                                            />
                                        </div>
                                        <DocumentCardActivity
                                            activity={`Created at ${dateToStr(p.item.createdAt)}`}
                                            people={(p.item.collabrators || []).map(i => ({
                                                ...i,
                                                profileImageSrc: App.REST_SERVER + "/attachments/download?id=" + i.pp_id
                                            }))}
                                        />
                                    </DocumentCard>
                                </div>
                            );
                        }}
                        emptyComponent={() => {
                            if (this.state.search)
                                return <div className={"tm-card"}><EmptyComponent
                                    dsc={'"' + this.state.search + '" Not Found!'}/></div>;
                            else
                                return <div className={"tm-card"}><EmptyComponent dsc="No Project"/></div>;
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default ProjectList;
