import React from 'react';
import {connect} from 'react-redux';
import {Icon} from 'office-ui-fabric-react';
import {App} from '../index';
import {lightenDarkenColor} from "../utils/utils";


const typeInfos = {
    success: {
        bgColor: "#107c10",
        icon: "Completed",
        title: "Success"
    },
    warning: {
        bgColor: "#ff8c00",
        icon: "Info",
        title: "Warning"
    },
    error: {
        bgColor: "#e81123",
        icon: "ErrorBadge",
        title: "Error"
    },
};

class NotificationManager extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.onClick = this.onClick.bind(this);
        this.clearFuncs = {};
    }

    onClick(key) {
        //App.actions.removeNotification({ key })
    }


    render() {
        const {notifications} = this.props;
        return (
            <div style={{position: "fixed", top: 8, right: 8, zIndex: 2}}>
                <div style={{width: 300}}>
                    {
                        (notifications || []).map(notification => {
                            return (
                                <MessageItem notification={notification} key={notification.key}/>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}


class MessageItem extends React.Component {

    componentDidMount() {
        const {notification: {key}} = this.props;
        const timer = 3000;
        setTimeout(() => {
            const item = document.getElementById(key);
            item.className = "iota-notification-item-out";
            setTimeout(() => {
                App.actions.removeNotification({key})
            }, timer - 2000)
        }, timer)
    }


    render() {
        const {notification: {message, key, type}} = this.props;
        const {bgColor, icon, title} = typeInfos[type];
        return (
            <div id={key}
                 className="iota-notification-item"
                 key={key}>
                <div style={{backgroundColor: bgColor, display: "flex", alignItems: "center", padding: "16px 4px"}}>
                    <div style={{margin: "0 8px", padding: "0 8px", color: "white"}} className="iota-centered-item">
                        <Icon iconName={icon}/>
                    </div>
                    <div style={{display: "inline-block", color: "white"}}>{title}</div>
                </div>
                <div style={{minHeight: 50, backgroundColor: lightenDarkenColor(bgColor, 50), padding: 16}}>
                    {message}
                </div>
            </div>
        )
    }
}


export default connect(state => ({
        notifications: state.notifications
    })
)(NotificationManager);

