require('string.prototype.includes');

const letters = {"İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç"};

String.prototype.turkishToLowerCase = function () {
    return this.replace(/(([İI]))+/g, function (letter) {
        return letters[letter];
    }).toLowerCase();
};

String.prototype.turkishToLocaleLowerCase = function () {
    return this.replace(/(([İI]))+/g, function (letter) {
        return letters[letter];
    }).toLocaleLowerCase();
};
