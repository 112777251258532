import React, { Component } from "react";
import FetchData from "../../components/FetchData";
import {
    Persona, DetailsList, SelectionMode, DetailsRow
} from "office-ui-fabric-react";
import Loading from "../../components/Loading";
import EmptyComponent from "../../components/EmptyComponent";
import ConnectedItem from "../../components/ConnectedItem";
import ConnectedList from "../../components/ConnectedList";
import { App } from "../../index";
import connect from "react-redux/es/connect/connect";
import SearchCommandBar from "../../components-tm/SearchCommandBar";
import Protected from "../../components/Protected";

class Users extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = { search: "" };

        App.actions.setTitle({ title: "Users List" });
        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/users",
                    label: "User List"
                }
            ]
        })
    }

    render() {
        const cmd = [
            {
                key: "newItem",
                name: "",
                iconProps: {
                    iconName: "AddFriend"
                },
                onClick: () => App.history.push("/users/new")
            }
        ];

        return (
            <div>
                <FetchData tableName="users" />

                <Protected>
                    <SearchCommandBar commandBar={{ items: cmd }}
                        searchBox={{
                            onChange: e => this.setState({ search: e })
                        }}
                    />
                </Protected>
                <div className="tm-cell">
                    <div className="ms-Grid">
                        <div className="ms-Grid-row">
                            <div className="ms-Col ms-sm12">
                                <UserList search={this.state.search} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const _UserList = props => {
    const { users: _users, load, id, search } = props;

    const users = (_users || []).filter(i => !search || (i.firstname + i.lastname + i.username + i.email).toLowerCase().indexOf(search.toLowerCase()) > -1)
    const columns = [
        {
            key: "column0",
            name: "",
            fieldName: "pp",
            minWidth: 30,
            maxWidth: 50,
            iconOnly: true,
            padding: true,
            isPadded: true,
            onRender: item => {
                return (
                    <Persona size={3}
                        imageUrl={item.pp_id ? App.REST_SERVER + "/attachments/download?id=" + item.pp_id : null}
                        text={item.firstname + " " + item.lastname} onRenderPrimaryText={() => <span />} />
                );
            }
        },
        {
            key: "column1",
            name: "First Name",
            fieldName: "firstname",
            minWidth: 100,
            maxWidth: 150
        },
        {
            key: "column2",
            name: "Last Name",
            fieldName: "lastname",
            minWidth: 100,
            maxWidth: 150
        },
        {
            key: "column3",
            name: "E-mail",
            fieldName: "email",
            minWidth: 100,
            maxWidth: 150
        }
    ];
    if (load === undefined) return <Loading />;
    if (!load) return <div className={"tm-card"}><EmptyComponent dsc="No User" /></div>;
    if (load)
        if (users.length)
            return (
                <DetailsList
                    items={users || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    setKey="set"
                    onRenderRow={e => {
                        return React.createElement(DetailsRow, {
                            onClick: event =>
                                App.history.push("/users/" + e.item.id),
                            key: e.itemIndex,
                            ...e,
                            className: "cursor-pointer"
                        });
                    }}
                    isHeaderVisible={true}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                />
            );
        else if (search) {
            return <EmptyComponent dsc={'"' + search + '"' + " Not Found"} />
        } else {
            return <EmptyComponent dsc={"No User!"} />
        }

};

const UserList = connect(state => ({
    users: state.data && state.data.users,
    load: state.data && state.data.users && state.data.users.length
}))(_UserList);

export default Users;