import {App} from "../index";


export const cookieByName = function (name) {
    let match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (match) return match[1];
    return null;
}
export const setCookie = function (name, value, hours) {
    let dateObj = new Date();
    dateObj.setTime(dateObj.getTime() + (hours * 60 * 60 * 1000));
    document.cookie = name + "=" + value + ";" + "expires=" + dateObj.toUTCString()
}

export const deleteCookie = function (name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const queries = {};

function reloadQuery(payload) {
    const {queryName, ...rest} = payload;
    const params = queries[queryName];
    if (params)
        fetchData({tableName: queryName, params, ...rest})
}

export function reloadAllQueries() {
    Object.keys(queries).map(item => {
        const query = queries[item]
        reloadQuery({queryName: item, ...query})
    })
}

function fetchUserInfo() {
    fetch(App.REST_SERVER + "/auth/autherized", {
        method: "GET",
        credentials: "include"
    })
        .then(i => {
            if (i.status === 201) {
                i.json().then(i2 => {
                    App.actions.userInfoFetched(i2)
                })
            } else if (i.status === 400 || i.status === 403) {
                App.actions.loggedOut({});
            } else {
                App.actions.loggedOut({})
                console.error("unexpected error")
            }
        }).catch(i => {
        App.actions.loggedOut({})
        console.error("error", i)
    });
}

function login(payload) {
    fetch(App.REST_SERVER + "/auth/login", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(i => {
            if (i.status === 201) {
                i.json().then(i2 => {
                    const {token, ...rest} = i2;
                    setCookie("tm-token", token, 24)
                    App.actions.userInfoFetched(rest);
                })
            } else if (i.status === 400 || i.status === 403) {
                App.actions.pushNotification({
                    type: "error",
                    message: "Username or password is not correct!"
                })
                App.actions.loggedOut({})
            } else {
                App.actions.loggedOut({})
                console.error("unexpected error")
            }
        }).catch(i => {
        App.actions.loggedOut({})
        console.error("error", i)
    });
}

function logout() {
    fetch(App.REST_SERVER + "/auth/logout", {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(i => {
            if (i.status === 201) {
                i.json().then(i2 => {
                    deleteCookie("tm-token");
                    App.actions.loggedOut({})
                })
            } else if (i.status === 400 || i.status === 403) {
                App.actions.loggedOut({})
            } else {
                App.actions.loggedOut({})
                console.error("unexpected error")
            }
        }).catch(i => {
        App.actions.loggedOut({})
        console.error("error", i)
    });
}

function upsertForm(payload, state) {
    const {formName, formPath, pk, values, onSave, notification: _notification} = payload;
    const notification = _notification === undefined ? true : _notification;
    const formValues = formName ? state.form[formName].values : {};

    const method = pk ? "update" : "create";

    fetch(App.REST_SERVER + "/" + formPath + "/" + method, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({...(pk || {}), ...formValues, ...(values || {})}),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(i => {
            if (i.status === 201) {
                i.json().then(i2 => {
                    reloadAllQueries();
                    if (notification)
                        App.actions.pushNotification({
                            type: "success",
                            message: "Saved successfully"
                        });
                    if (onSave)
                        onSave(i2);
                })
            } else if (i.status === 400 || i.status === 403) {
                App.actions.loggedOut({})
                App.actions.pushNotification({
                    type: "warning",
                    message: "Your session has expired!"
                });
            } else {
                App.actions.loggedOut({});
                console.error("unexpected error");
            }
        });
}

function fetchData(payload) {
    const {tableName, params, onFetch} = payload;
    const body = params ? {body: JSON.stringify(params)} : {};
    fetch(App.REST_SERVER + "/" + tableName + "/fetchData", {
        method: params ? "POST" : "GET",
        credentials: "include",
        ...body,
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(i => {
            if (i.status === 201) {
                i.json().then(i2 => {
                    queries[tableName] = params;
                    App.actions.fetchDataSuccess({
                        name: tableName,
                        data: i2
                    });
                    if (onFetch) {
                        onFetch()
                    }
                })
            } else if (i.status === 400 || i.status === 403) {
                App.actions.loggedOut({})
                App.actions.pushNotification({
                    type: "warning",
                    message: "Your session has expired!"
                });
            } else {
                App.actions.loggedOut({});
                console.error("unexpected error");
            }
        });
}

function deleteRecord(payload) {
    const {deletePath, pk, onDelete} = payload;
    fetch(App.REST_SERVER + "/" + deletePath + "/deleteRecord", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(pk),
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(i => {
            if (i.status === 201) {
                i.json().then(i2 => {
                    App.actions.pushNotification({
                        type: "success",
                        message: "Delete successfully"
                    });
                    if (onDelete)
                        onDelete(i2);
                })
            } else if (i.status === 400 || i.status === 403) {
                App.actions.loggedOut({});
                App.actions.pushNotification({
                    type: "warning",
                    message: "Your session has expired!"
                });
            } else {
                App.actions.loggedOut({});
                console.error("unexpected error");
            }
        });
}


export {fetchUserInfo, login, logout, fetchData, upsertForm, deleteRecord, reloadQuery};
