import React from 'react';
import {Breadcrumb, Icon} from 'office-ui-fabric-react'
import {connect} from 'react-redux'
import {App} from "../index";
import Sticky from "react-sticky-el";

const AppBreadcrumb = props => {

    const {breadcrumb} = props;

    const paths = [{
        text: <Icon iconName="Home"/>,
        key: "Home",
        onClick: () => App.history.push("/")
    },
        ...(breadcrumb || []).map(i => ({text: i.label, onClick: () => App.history.push(i.path)}))];

    return (
        <Breadcrumb
            className="iota-breadcrumb ms-bgColor-themePrimary ms-fontColor-white ms-bgColor-themePrimary--hover"
            items={paths}
            styles={{
                listItem:
                    {
                        backgroundColor: "#0078d4 !important",
                        color: "white",
                    },
                chevron:
                    {
                        backgroundColor: "#0078d4 !important",
                        color: "white",
                    },
                itemLink:

                    {
                        backgroundColor: "#0078d4 !important",
                        color: "white"
                    }
                ,
            }}
        />
    )
};

const _StickyBreadCrumb = props => {
    const {breadcrumb} = props;

    if (!breadcrumb || !breadcrumb.length)
        return null;

    return (
        <Sticky boundaryElement={".app-bar"}
                topOffset={-140}
                stickyClassName={"sticky-breadCrumb"}>
            <div style={{height: 55, overflow: "hidden"}}>
                <AppBreadcrumb breadcrumb={breadcrumb}/>
            </div>
        </Sticky>
    )
}

const StickyBreadCrumb = connect(state => ({
    breadcrumb: state.app && state.app.breadcrumb
}))(_StickyBreadCrumb);

export default StickyBreadCrumb;
