import React, {Component} from "react";
import {PrimaryButton} from "office-ui-fabric-react";
import Attachments from "./Attachments";
import Comments from "./Comments";

const attachmentComponents = {
    attachment: Attachments,
    comment: Comments
};

class RecordExtension extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {selectedTab: "attachment"};
    }

    onClick(selectedTab) {
        this.setState({selectedTab});
    }

    render() {
        const buttons = [
            {
                icon: "Attach",
                text: "Attachments",
                fieldKey: "attachment"
            },
            {
                icon: "Comment",
                text: "Comments",
                fieldKey: "comment"
            }
        ];
        return (
            <div className="ms-Grid" style={{padding: 0}}>
                <div className="ms-Grid-row" style={{margin: "8px 0"}}>
                    <div
                        className={"ms-Grid-col ms-sm12 ms-md12 ms-lg12"}
                        style={{backgroundColor: "white", padding: 0, minHeight: 100}}
                    >
                        <div style={{borderBottom: "1px solid #ccc"}}>
                            {buttons.map((button, index) => {
                                const {icon, text, fieldKey} = button;
                                const checked = this.state.selectedTab === fieldKey;
                                let style = {};
                                if (!checked)
                                    style = {
                                        root: {backgroundColor: "white", color: "black"},
                                        icon: {color: "rgb(16, 110, 190)"}
                                    };
                                else style = {icon: {color: "white"}};
                                return (
                                    <PrimaryButton
                                        iconProps={{iconName: icon}}
                                        key={index}
                                        text={text}
                                        onClick={() => this.onClick(fieldKey)}
                                        styles={{
                                            root: {
                                                height: 40,
                                                width: "50%",
                                                ...(style.root || {})
                                            },
                                            ...(style.icon || {})
                                        }}
                                    />
                                );
                            })}
                        </div>
                        {React.createElement(attachmentComponents[this.state.selectedTab], {
                            ...this.props
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default RecordExtension;
