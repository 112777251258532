/* eslint react/prop-types: 0 */
import React from 'react';
import {
    CheckboxVisibility,
    DetailsListLayoutMode,
    DetailsRow,
    DetailsList,
    Icon,
    PrimaryButton
} from "office-ui-fabric-react";
import FieldCreator from './FieldCreator'
import {dateToStr} from "../utils/utils";
import EmptyComponent from './EmptyComponent'

const fieldTypes = {
    toggle: "switch",
};
const typeToComponent = {
    date: (item) => {
        return <div>{dateToStr(item)}</div>
    }
};

const typeToText = {
    date: (item) => dateToStr(item)
};

class JsonArrayValueController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            columns: [],
            draggedIndex: -1,
            draggedItem: null,
        }
    }


    _getDragDropEvents() {
        return {
            canDrop: (dropContext, dragContext) => {
                return true;
            },
            canDrag: (item) => {
                return true;
            },
            onDragEnter: (item, event) => {
                return 'dragEnter';
            }, // return string is the css classes that will be added to the entering element.
            onDragLeave: (item, event) => {
                return;
            },
            onDragOver: (item, event) => {
                if (this.state.draggedItem != item.index) {
                    this.props.onSwap(item.index, this.state.draggedIndex);
                    this.setState({draggedIndex: item.index});
                }
            },
            onDrop: (item, event) => {
                if (this.state.draggedItem) {
                    this.props.onSwap(item.index, this.state.draggedIndex)
                    //this._insertBeforeItem(item);
                }
            },
            onDragStart: (item, itemIndex, selectedItems, event) => {
                this.setState({draggedItem: item, draggedIndex: itemIndex})
            },
            onDragEnd: (item, event) => {
                this.setState({draggedItem: null, draggedIndex: -1})
            }
        };
    }


    render() {
        const {items, fieldStruct} = this.props;
        const {onDelete, onEdit, onSwap, onCopy, editingIndex, ...rest} = this.props;
        const buttonColumns = [
            {
                key: "delete",
                name: "",
                fieldName: "delete",
                minWidth: 10,
                maxWidth: 20,
                className: "field-button",
                isRowHeader: true,
                isResizable: false,
                onRender: e => <Icon iconName="Clear" className="field-button" onClick={() => onDelete(e.index)}/>,
                isPadded: true,
                isSorted: false,
                isSortedDescending: false,
                data: "delete",
            },
            {
                key: "copy",
                name: "",
                fieldName: "copy",
                className: "field-button",
                minWidth: 10,
                maxWidth: 20,
                isRowHeader: true,
                isResizable: false,
                onRender: e => <Icon iconName="Copy" className="field-button" onClick={() => onCopy(e.index)}/>,
                isPadded: true,
                isSorted: false,
                isSortedDescending: false,
                data: "copy"
            }
        ]

        const columns = (fieldStruct || []).map((info, index) => {
                if (info.label && info.label != "-" && info.type != "hiddenfield") {
                    return {
                        key: info.keyName,
                        name: info.label,
                        fieldName: info.keyName,
                        minWidth: 100,
                        maxWidth: 150,
                        isRowHeader: true,
                        isResizable: true,
                        onRender: typeToComponent[info.type] ? (item) => typeToComponent[info.type](item[info.keyName]) : undefined,
                        isPadded: true,
                        isSorted: false,
                        isSortedDescending: false,
                        data: info.type,
                    }
                } else
                    return null
            }
        ).filter(i => i);
        return (
            <div className="ms-Grid">
                <div className="ms-Grid-row" style={{margin: "16px 1px"}}>
                    <div style={{backgroundColor: "white"}} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {
                            columns ?
                                <DetailsList
                                    setKey='items'
                                    items={items}
                                    usePageCache
                                    dragDropEvents={this._getDragDropEvents()}
                                    columns={[...buttonColumns, ...columns].map((i, ind) => ({...i, key: ind}))}
                                    onRenderRow={e => {
                                        return React.createElement(DetailsRow, {
                                            onClick: (event) => event.target.className.indexOf("field-button") > -1 ? null : onEdit(e.itemIndex),
                                            key: e.itemIndex,
                                            ...e,
                                            onDragOver: () => {
                                                if (this.state.draggedIndex != e.itemIndex) {
                                                    onSwap(e.itemIndex, this.state.draggedIndex);
                                                    this.setState({draggedIndex: e.itemIndex});
                                                }
                                            },
                                            item: {...e.item, index: e.itemIndex},
                                            className: "iota-cursor-pointer"
                                        })
                                    }}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selectionPreservedOnEmptyClick={true}
                                    checkboxVisibility={CheckboxVisibility["hidden"]}
                                />
                                : null}

                        {items && items.length ? null : <EmptyComponent dsc={"Kayıt Bulunmamaktadır"}/>}

                    </div>
                </div>
            </div>
        )
    }
}

class JsonArray extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {editingIndex: -1};
        (props.fields || []).sort((i1, i2) => i1.order > i2.order ? 1 : -1).forEach(i => {
            this.initialState[i.keyName] = ""
        });

        this.state = this.initialState;
        this.onKeyPressEnter = this.onKeyPressEnter.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSwap = this.onSwap.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCopy = this.onCopy.bind(this);
    }

    onCopy(index) {
        const {value, onChange} = this.props.input || this.props;
        onChange([...value, value[index]]);
    }

    onAdd(_value) {
        if (!_value)
            return;
        const {value, onChange} = this.props.input || this.props;

        if (this.state.editingIndex > -1) {
            const newValue = [...value];
            newValue[this.state.editingIndex] = _value;
            onChange(newValue);
        } else {
            onChange([...value, _value]);
        }
        this.setState(this.initialState)
    }

    onDelete(index) {
        const {value, onChange} = this.props.input || this.props;
        const newValue = [...value];
        newValue[index] = undefined;
        if (this.state.editingIndex > -1)
            this.setState({...this.initialState, ...this.state.tempValue});
        else
            this.setState(this.initialState);
        onChange(newValue.filter(i => i))
    }

    onSwap(draggingIndex, droppingIndex) {
        const {value, onChange} = this.props.input || this.props;
        const newValue = [...value];
        const tempValue = newValue[draggingIndex];
        newValue[draggingIndex] = newValue[droppingIndex];
        newValue[droppingIndex] = tempValue
        onChange(newValue);
    }

    onEdit(index) {
        const {value} = this.props.input || this.props;
        if (this.state.editingIndex != index)
            this.setState({
                ...value[index],
                editingIndex: index,
                tempValue: this.state.editingIndex > -1 ? this.state.tempValue : this.state.value
            });
        else
            this.setState({...this.initialState, ...this.state.tempValue})
    }

    onChange(keyName, value) {
        this.setState({[keyName]: value})
    }

    onKeyPressEnter(e) {
        if (e.which === 13 || e.keyCode === 13)
            this.onAdd(this.state)
    }

    render() {
        const {value, onChange, ..._rest} = this.props.input || this.props;
        const {initalValue, label, fields: _fields, ...rest} = this.props;
        const fields = _fields.filter(i => i.label && i.label !== "-");
        return [
            <p key={label}>{label}</p>,
            <JsonArrayValueController key={"viewer"}
                                      items={value}
                                      fieldStruct={fields}
                                      onEdit={this.onEdit}
                                      onSwap={this.onSwap}
                                      onCopy={this.onCopy}
                                      onDelete={this.onDelete}
                                      editingIndex={this.state.editingIndex}
            />,
            <div style={{display: "flex", alignItems: "flex-end", padding: "16px 0"}} key={"fields"}>
                {(fields || []).map((field, index) => {
                    const {type, ...rest} = field;
                    if (type && type != "hiddenfield") {
                        return (
                            <div
                                className={`ms-Grid-col ms-sm12 ms-md${12 / fields.length} ms-lg${12 / fields.length}`}
                                key={index}>
                                <FieldCreator {...rest}
                                              type={fieldTypes[type] || type}
                                              value={this.state[field.keyName]}
                                              onChange={e => this.onChange(field.keyName, e)}/>
                            </div>
                        )
                    }
                    return null//<div key={index}>bilinmeyen alan ({type}) ({field.keyName})</div>
                })
                }
                <PrimaryButton onClick={() => this.onAdd(this.state)} text={"Ekle"} iconProps={{iconName: "Add"}}/>
            </div>
        ]
    }
};
export default JsonArray