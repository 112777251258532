import React from "react";
import {App} from "../../index";
import {Icon} from "office-ui-fabric-react";
import {connect} from "react-redux";

const paths = [
    {
        path: "/",
        name: "Home",
        icon: "Home"
    },
    {
        path: "projects",
        name: "Projects",
        icon: "Articles"
    },
    {
        path: "users",
        name: "Users",
        icon: "People"
    }
];

class PermanentDrawer extends React.Component {
    state = {selectedKey: ""};

    handleClick(path, selectedKey) {
        this.setState({selectedKey});
        App.history.push(path != "/" ? "/" + path : "/");
    }

    render() {
        const selected = window.location.hash.slice(2, window.location.hash.length);

        return (
            <div className={"left-nav"}>
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        height: "100%",
                        overflow: "hidden",
                        transition: "0.4s"
                    }}
                    className="left-nav"
                >
                    <div
                        className="left-nav-title"
                        style={{cursor: "pointer", display: "flex", alignItems: "center"}}
                        onClick={() => App.history.push("/")}
                    >
                        <Icon
                            iconName="CollapseMenu"
                            className="iota-centered-item"
                            style={{
                                display: "flex",
                                color: "rgb(0,120,212)",
                                fontWeight: "bold",
                                minWidth: 64,
                                height: 64
                            }}
                        />
                        <div className="ms-font-l" style={{fontWeight: "bold", fontSize: 16}}>
                            Project Manager
                        </div>
                    </div>
                    <ul className="left-nav-link-parent">
                        {paths.map((item, index) => {
                            return (
                                <li
                                    className={
                                        item.path === (selected || "/")
                                            ? "selected-left-nav-link-item"
                                            : "left-nav-link-item"
                                    }
                                    onClick={() => this.handleClick(item.path)}
                                    key={index}
                                    style={{display: "flex", alignItems: "center"}}
                                >
                                    <Icon
                                        iconName={item.icon}
                                        className="iota-centered-item left-nav-icon"
                                        style={{display: "flex"}}
                                    />
                                    <p style={{whiteSpace: "nowrap"}}>{item.name}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    routes: state.routes,
    fullScreen: state.app && state.app.fullScreen
}))(PermanentDrawer);
