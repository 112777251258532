import React from "react";
import "whatwg-fetch";
import PropTypes from 'prop-types'
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import store from "./utils/store";
import {syncHistoryWithStore} from "react-router-redux";
import {createHashHistory} from "history";
import "./utils/globals";
import "./css/app.css";
import * as authActions from "./actions/auth";
import * as appActions from "./actions/index";
import * as fileActions from "./actions/files";
import NotificationManager from "./components/NotificationManager";
import "office-ui-fabric-react/dist/css/fabric.css";
import {Fabric} from "office-ui-fabric-react";
import {initializeIcons} from "@uifabric/icons";
import {loadTheme} from "office-ui-fabric-react";
import "./fonts/segoeui-regular.woff2";
import MainPage from "./main";
import bindActionCreators from 'redux/es/bindActionCreators';

loadTheme({
    palette: {
        themePrimary: "#0078d4"
    }
});

initializeIcons();
const moment = require("moment");
moment.locale("tr");


const App = {actions: {}};
App.store = store;


class ApplicationBinder extends React.Component {

    static contextTypes = {
        store: PropTypes.object
    };

    componentWillMount() {
        App.actions = bindActionCreators({...authActions, ...appActions, ...fileActions}, this.context.store.dispatch);
    }

    render() {
        return null;
    }
}

App.animationDuration = 300;

const _history = createHashHistory();
const history = syncHistoryWithStore(_history, store);
App.history = history;

App.REST_SERVER = "/api";

export {App};

const AppRenderer = () => {
    return (
        <Provider store={store}>
            <Fabric>
                <ApplicationBinder/>
                <NotificationManager/>
                <MainPage/>
            </Fabric>
        </Provider>
    );
};

ReactDOM.render(<AppRenderer/>, document.getElementById("root"));
