import * as actions from "../actions/index";

export default function app_reducer(state = {}, action) {
    const {title, path, label, breadcrumb} = action.payload || {};
    switch (action.type) {
        case `${actions.setTitle}`:
            return {...state, title};
        case `${actions.setBreadcrumb}`:
            return {...state, breadcrumb};
        case `${actions.addBreadcrumb}`:
            return {...state, breadcrumb: [...state.breadcrumb, {path, label}]};
        default:
            return state;
    }
}