import React, {Component} from "react";
import FieldCreator from "../../components/FieldCreator";
import {reduxForm} from "redux-form";
import {Button, PrimaryButton} from "office-ui-fabric-react";
import {App} from "../..";
import FormLoader from "../../components-tm/FormLoader";

class ProjectEdit extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.onSave = this.onSave.bind(this);
        const {id} = props.match.params;

        App.actions.setTitle({title: id ? "Project Edit" : "New Project"});

        const editPaths = id ? [{
            path: "/projects/" + id,
            label: "Project Detail"
        },
            {
                path: "/projects/" + id + "/edit",
                label: "Project Edit"
            }] : [{
            path: "/projects/new",
            label: "New Project"
        }];

        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/projects",
                    label: "Project List"
                },
                ...editPaths
            ]
        })
    }

    onSave() {
        const {id} = this.props.match.params;
        App.actions.upsertForm({
            formName: "project-edit",
            formPath: "projects",
            pk: id ? {id} : undefined,
            onSave: (item) => id ? App.history.goBack() : App.history.push("/projects/" + item.id)

        });
    }

    render() {
        const {id} = this.props.match.params;
        return (
            <div className="tm-card tm-cell">
                <FormLoader
                    formName="project-edit"
                    queryPath={"projects"}
                    params={{id}}
                    isNew={!id}
                />
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Title" name="title"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Description" name="dsc"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="date" label="Finish Date" name="finish_date"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator
                        type="persona"
                        queryPath="users"
                        valField="id"
                        insertItem={true}
                        isArray
                        textField="name"
                        label="Collaborators"
                        name="collabrators"
                    />
                </div>
                <span className="tm-cell">
          <Button onClick={() => App.history.goBack()}>Decline</Button>
        </span>
                <span className="tm-cell">
          <PrimaryButton onClick={this.onSave}>Save</PrimaryButton>
        </span>
            </div>
        );
    }
}

export default reduxForm({form: "project-edit"})(ProjectEdit);
