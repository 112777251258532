import React, {Component} from "react";
import FetchData from "../../components/FetchData";
import CardTitle from "../../components/CardTitle";
import ConnectedList from "../../components/ConnectedList";
import {
    Persona,
    CommandBar,
    DetailsList,
    SelectionMode,
    DetailsRow,
    Icon,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    DefaultButton,
    ProgressIndicator
} from "office-ui-fabric-react";
import DisplayItem from "../../components/DisplayItem";
import RecordExtension from "../../components-tm/RecordExtension";
import {App} from "../..";
import EmptyComponent from "../../components/EmptyComponent";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import StatusBar from "../../components-tm/StatusBar";
import DeleteDialog from "../../components-tm/DeleteDialog";
import {dateToStr} from "../../utils/utils";
import ConnectedItem from "../../components/ConnectedItem";

class TaskDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {doneDialog: false};
        const {id, task_id} = props.match.params;

        App.actions.setTitle({title: "Task Detail"});

        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/projects",
                    label: "Project List"
                },
                {
                    path: "/projects/" + id,
                    label: "Project Detail"
                },
                {
                    path: "/projects/" + id + "/tasks/" + task_id,
                    label: "Task Detail"
                }
            ]
        })

    }

    render() {
        const {id, task_id} = this.props.match.params;
        const cmd = item => [
            {
                key: "newItem",
                name: "Edit",
                iconProps: {
                    iconName: "Edit"
                },
                onClick: () =>
                    App.history.push("/projects/" + id + "/tasks/" + task_id + "/edit")
            },
            {
                key: "delete",
                name: "Delete",
                iconProps: {
                    iconName: "Delete"
                },
                onClick: () => this.setState({deleteRecordId: task_id})
            },
            {
                key: "check",
                name: "Check",
                iconProps: {
                    iconName: "Add"
                },
                onClick: () =>
                    App.history.push(
                        "/projects/" + id + "/tasks/" + task_id + "/newCheck"
                    )
            },
            !item.status
                ? {
                    key: "done",
                    name: "Done",
                    iconProps: {
                        iconName: "Completed"
                    },
                    onClick: () => this.setState({doneDialog: true})
                }
                : null
        ].filter(i => i);

        return (
            <div className="tm-cell">
                <ConnectedItem path={["auth", "userInfo"]}
                               component={p2 => {
                                   return (
                                       <ConnectedList
                                           path={["data", "tasks"]}
                                           component={p => {
                                               const items = p2.item.id === 1 ? cmd(p.item) : [];
                                               if (p.item.status || !items.length)
                                                   return null;
                                               return <CommandBar items={items}/>;
                                           }}
                                       />
                                   )
                               }}/>

                <DeleteDialog pk={{id: this.state.deleteRecordId}} open={this.state.deleteRecordId}
                              onClose={() => this.setState({deleteRecordId: false})}
                              deletePath={"tasks"}
                              onDelete={() => App.history.push("/projects/" + id)}
                />

                <DoneDialog
                    open={this.state.doneDialog}
                    onClose={() => this.setState({doneDialog: false})}
                    id={task_id}
                />
                <div className="ms-Grid">
                    <FetchData tableName="tasks" params={{id: task_id}}/>
                    <FetchData tableName="checklists" params={{task_id}}/>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm8">
                            <ConnectedList
                                path={["data", "tasks"]}
                                component={p => {
                                    return (
                                        <div>
                                            <StatusBar status={p.item.status}/>
                                            <div className="tm-card" style={{padding: 16}}>
                                                <DisplayItem icon={"TextDocument"} label={"Title"}>
                                                    {p.item.title}
                                                </DisplayItem>
                                                <DisplayItem icon={"TextDocument"} label={"Description"}>
                                                    {p.item.dsc}
                                                </DisplayItem>
                                                <DisplayItem icon={"Calendar"} label={"Created Date"}>
                                                    {dateToStr(p.item.createdAt)}
                                                </DisplayItem>
                                                <DisplayItem icon={"DateTime"} label={"Finish Date"}>
                                                    {dateToStr(p.item.finish_date)}
                                                </DisplayItem>
                                                {p.item.done_date ?
                                                    <DisplayItem icon={"EventAccepted"} label={"Done Date"}>
                                                        {dateToStr(p.item.done_date)}
                                                    </DisplayItem> : null}
                                                <DisplayItem icon={"AwayStatus"} label={"Status"}>
                                                    <ProgressIndicator
                                                        description={
                                                            (p.item.done_count || "0") + "/" + p.item.check_count
                                                        }
                                                        percentComplete={
                                                            p.item.done_count / p.item.check_count
                                                        }
                                                    />
                                                </DisplayItem>
                                            </div>
                                        </div>
                                    );
                                }}
                                loadingComponent={
                                    () => {
                                        return (
                                            <div className={"tm-card"}>
                                                <Loading/>
                                            </div>
                                        )
                                    }
                                }
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4">
                            <div className="tm-card">
                                <ConnectedList
                                    path={["data", "tasks"]}
                                    component={p => {
                                        return (
                                            <div>
                                                <CardTitle icon={"Group"}>Collaborators</CardTitle>
                                                <div className="tm-cell">
                                                    {p.item.collabrators && p.item.collabrators.length ? (
                                                        p.item.collabrators.map((user, index) => {
                                                            return (
                                                                <Persona
                                                                    size={3}
                                                                    imageUrl={App.REST_SERVER + "/attachments/download?id=" + user.pp_id}
                                                                    text={user.name}
                                                                    secondaryText={user.username}
                                                                    key={index}
                                                                    styles={{root: {margin: "8px 0"}}}
                                                                />
                                                            );
                                                        })
                                                    ) : (
                                                        <EmptyComponent dsc="No Collaborator"/>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                    loadingComponent={
                                        () => {
                                            return (
                                                <div className={"tm-card"}>
                                                    <Loading/>
                                                </div>
                                            )
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <div className="tm-card tm-cell">
                            <CardTitle icon={"CheckList"}>Check-List</CardTitle>
                            <CheckList id={id} task_id={task_id}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="ms-Grid-col ms-sm12">
                        <RecordExtension parentType="tasks" parentId={task_id}/>
                    </div>
                </div>
            </div>
        );
    }
}

const _CheckList = props => {
    const {checklists, load, id, task_id} = props;

    const columns = [
        {
            key: "column0",
            name: "Status",
            iconName: "Status",
            fieldName: "status",
            minWidth: 30,
            maxWidth: 50,
            iconOnly: true,
            padding: true,
            isPadded: true,
            onRender: item => {
                return (
                    <Icon
                        styles={{root: {fontSize: 18}}}
                        iconName={item.status ? "Completed" : "DRM"}
                    />
                );
            }
        },
        {
            key: "column1",
            name: "Title",
            fieldName: "title",
            minWidth: 100,
            maxWidth: 150
        },
        {
            key: "column2",
            name: "Description",
            fieldName: "dsc",
            minWidth: 100,
            maxWidth: 150
        },
        {
            key: "column3",
            name: "Collaborators",
            fieldName: "collabrators",
            minWidth: 100,
            maxWidth: 150,
            onRender: item => {
                if (item.collabrators && item.collabrators.length) {
                    return item.collabrators.map((user, index) => (
                        <Persona
                            text={user.name}
                            imageUrl={App.REST_SERVER + "/attachments/download?id=" + user.pp_id}
                            secondaryText={user.username}
                            key={index}
                            size={1}
                            styles={{root: {margin: "8px 0"}}}
                        />
                    ));
                }
                return <div>No Collaborator</div>;
            }
        }
    ];
    if (load === undefined) return <Loading/>;
    if (!load) return <EmptyComponent dsc="No Check-list"/>;
    if (load)
        return (
            <DetailsList
                items={checklists || []}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="set"
                onRenderRow={e => {
                    return React.createElement(DetailsRow, {
                        onClick: event =>
                            App.history.push(
                                "/projects/" + id + "/tasks/" + task_id + "/checks/" + e.item.id
                            ),
                        key: e.itemIndex,
                        ...e,
                        className: "cursor-pointer"
                    });
                }}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
            />
        );
};

const CheckList = connect(state => ({
    checklists: state.data && state.data.checklists,
    load: state.data && state.data.checklists && state.data.checklists.length
}))(_CheckList);

const DoneDialog = props => {
    const {open, onClose, id} = props;

    function onDone() {
        App.actions.upsertForm({
            formPath: "tasks",
            pk: {id},
            values: {
                status: 1,
                done_date: new Date()
            },
            onSave: i => {
                onClose();
            }
        });
    }

    return (
        <Dialog
            hidden={!open}
            onDismiss={onClose}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: "Are you sure?",
                subText:
                    "Are you sure to complete? This action can't undo!"
            }}
            modalProps={{
                isBlocking: false,
                containerClassName: "ms-dialogMainOverride"
            }}
        >
            <DialogFooter>
                <PrimaryButton onClick={onDone} text="Done"/>
                <DefaultButton onClick={onClose} text="Cancel"/>
            </DialogFooter>
        </Dialog>
    );
};

export default TaskDetail;
