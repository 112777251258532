import React from "react";
import {Icon} from "office-ui-fabric-react/lib/Icon";
import {App} from "../..";
import {connect} from "react-redux";
import FetchData from "../../components/FetchData";
import ConnectedItem from "../../components/ConnectedItem";
import CardTitle from "../../components/CardTitle";
import {DetailsList, DetailsRow, Persona, SelectionMode} from "office-ui-fabric-react";
import Loading from "../../components/Loading";
import EmptyComponent from "../../components/EmptyComponent";
import {dateToStr} from "../../utils/utils";

export default class Home extends React.Component {
    constructor(props, context) {
        super(props, context);
        App.actions.setTitle({title: "Home"});
        App.actions.setBreadcrumb({
            breadcrumb: []
        })
    }

    render() {
        return (
            <div>
                <Content/>
            </div>
        );
    }
}

const Content = props => {
    return (
        <div className="ms-Grid">
            <TaskCountCards/>
            <Tasks/>
        </div>
    );
};

const Tasks = props => {
    return (
        <div className="ms-Grid-row iota-row">
            <FetchData tableName="dashboard/tasks"/>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <div style={{backgroundColor: "white", padding: 16}}>
                    <CardTitle icon={"TaskManager"}>Assigned to Me</CardTitle>
                    <MyTasks/>
                </div>
            </div>
        </div>
    )
}

const TaskCountCards = props => {
    return (
        <div className="ms-Grid-row">
            <FetchData tableName="dashboard/task_count"/>
            <ConnectedItem path={["data", "dashboard/task_count", 0]} component={p => {
                const {completed_tasks, expired_tasks, on_progress_tasks, checklist_count} = p.item;
                return (
                    <div style={{width: "100%"}}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <CountCard color="#0078d4"
                                       count={checklist_count}
                                       label={"Checklist"}
                                       icon={"CheckList"}/>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <CountCard color="#e81123"
                                       count={expired_tasks}
                                       label={"Expired"}
                                       icon={"CaloriesAdd"}/>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <CountCard color="#ff8c00"
                                       count={on_progress_tasks}
                                       label={"On Progress"}
                                       icon={"CalendarSettingsMirrored"}/>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                            <CountCard color="#107c10"
                                       count={completed_tasks}
                                       label={"Completed"}
                                       icon={"Completed"}/>
                        </div>
                    </div>
                )
            }}/>
        </div>
    )
};

class CountCard extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {count: 0}
    }


    componentDidMount() {
        const {count: _count} = this.props;
        let inte;
        if (this.state.count < _count)
            inte = setInterval(() => {
                this.setState({count: this.state.count + 1}, () => {
                    if (this.state.count >= _count)
                        clearInterval(inte);
                })
            }, 1000 / _count);

    }


    render() {
        const {color, icon, label} = this.props;

        return (
            <div style={{color: "white", width: "100%"}}>
                <div
                    style={{
                        height: 150,
                        margin: "8px 0",
                        backgroundColor: color
                    }}
                >
                    <div style={{width: "100%", height: "100%"}} className="center">
                        <div className="center">
                            <div style={{padding: 16}}>
                                <Icon iconName={icon} style={{fontSize: 54}}/>
                            </div>
                        </div>
                        <div style={{width: "80%", float: "right", height: "100%"}}>
                            <div className="center" style={{height: "100%"}}>
                                <div style={{padding: 16, textAlign: "center"}}>
                                    <div className="ms-font-xxl ms-fontSize-su">{this.state.count}</div>
                                    <div className="ms-font-xl">{label}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const _MyTasks = props => {
    const {tasks, load, id} = props;

    const columns = [
        {
            key: "column0",
            name: "Status",
            iconName: "Status",
            fieldName: "status",
            minWidth: 30,
            maxWidth: 50,
            iconOnly: true,
            padding: true,
            isPadded: true,
            onRender: item => {
                return (
                    <Icon
                        styles={{root: {fontSize: 18}}}
                        iconName={item.status ? "Completed" : "DRM"}
                    />
                );
            }
        },
        {
            key: "column1",
            name: "Title",
            fieldName: "title",
            minWidth: 100,
            maxWidth: 150
        },
        {
            key: "column2",
            name: "Description",
            fieldName: "dsc",
            minWidth: 100,
            maxWidth: 150
        },
        {
            key: "column3",
            name: "Collaborators",
            fieldName: "collabrators",
            minWidth: 100,
            maxWidth: 150,
            onRender: item => {
                if (item.collabrators && item.collabrators.length) {
                    return item.collabrators.map((user, index) => (
                        <Persona
                            text={user.name}
                            imageUrl={App.REST_SERVER + "/attachments/download?id=" + user.pp_id}
                            secondaryText={user.username}
                            key={index}
                            size={1}
                            styles={{root: {margin: "8px 0"}}}
                        />
                    ));
                }
                return <div>No Collaborator</div>;
            }
        },
        {
            key: "column4",
            name: "Finish Date",
            fieldName: "finish_date",
            minWidth: 30,
            maxWidth: 50,
            iconOnly: true,
            padding: true,
            isPadded: true,
            onRender: item => {
                return dateToStr(item.finish_date);
            }
        }
    ];
    if (load === undefined) return <Loading/>;
    if (!load) return <EmptyComponent dsc="No Task"/>;
    if (load)
        return (
            <DetailsList
                items={tasks || []}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="set"
                onRenderRow={e => {
                    return React.createElement(DetailsRow, {
                        onClick: event =>
                            App.history.push("/projects/" + e.item.project_id + "/tasks/" + e.item.id),
                        key: e.itemIndex,
                        ...e,
                        className: "cursor-pointer"
                    });
                }}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
            />
        );
};

const MyTasks = connect(state => ({
    tasks: state.data && state.data["dashboard/tasks"],
    load: state.data && state.data["dashboard/tasks"] && state.data["dashboard/tasks"].length
}))(_MyTasks);
