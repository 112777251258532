import React from "react";
import { connect } from "react-redux";
import { fetchData } from "../actions";
import Actions from "redux-form/es/actions";

const formInitialize = Actions.initialize;

class FormLoader extends React.Component {
  static defaultProps = {};

  constructor(props, context) {
    super(props, context);
    this.props.initialValues && this.initialize(this.props.initialValues);
    this.state = {
      _initialized: !!this.props.initialValues
    };
    this.initialize = this.initialize.bind(this);
  }

  initialize([data]) {
    if (this.props.initialize) this.props.initialize(data);
    else
      this.props.formInitialize(
        this.props.formName,
        this.props.isNew ? this.props.formInitialData || {} : data
      );
  }

  componentWillMount() {
      this.props.fetchData({
        tableName: this.props.queryPath,
        params: this.props.params
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues && !this.state._initialized) {
      this.setState({ _initialized: true }, () =>
        this.initialize(nextProps.initialValues)
      );
    }
  }

  componentWillUnmount() {
    // this.props.clearFormResult({
    //   name: this.props.formName
    // });
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return null;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialValues: state.data && state.data[ownProps.queryPath]
  };
}

export default connect(
  mapStateToProps,
  { fetchData, formInitialize }
)(FormLoader);
