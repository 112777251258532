import React, {Component} from "react";
import FieldCreator from "../../components/FieldCreator";
import {reduxForm} from "redux-form";
import {Button, PrimaryButton} from "office-ui-fabric-react";
import {App} from "../..";
import FormLoader from "../../components-tm/FormLoader";

class TaskEdit extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.onSave = this.onSave.bind(this);
        const {id, task_id} = props.match.params;

        const editPaths = task_id ?
            [{
                path: "/projects/" + id + "/tasks/" + task_id,
                label: "Task Detail"
            },
                {
                    path: "/projects/" + id + "/task/" + task_id + "/edit",
                    label: "Task Edit"
                }] :
            [{
                path: "/projects/" + id + "/task/new",
                label: "New Task"
            }]

        App.actions.setTitle({title: "Task Edit"});

        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/projects",
                    label: "Project List"
                },
                {
                    path: "/projects/" + id,
                    label: "Project Detail"
                },
                ...editPaths
            ]
        })

    }

    onSave() {
        const {id, task_id} = this.props.match.params;
        App.actions.upsertForm({
            formName: "task-edit",
            formPath: "tasks",
            pk: task_id ? {id: task_id} : undefined,
            onSave: (task) => task_id ? App.history.goBack() : App.history.push("/projects/" + id + "/tasks/" + task.id)
        });
    }

    render() {
        const {id, task_id} = this.props.match.params;
        return (
            <div className="tm-card tm-cell">
                <FormLoader
                    formName="task-edit"
                    queryPath={"tasks"}
                    params={{id: task_id}}
                    isNew={!task_id}
                    formInitialData={{project_id: id, status: 0}}
                />
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Title" name="title"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Description" name="dsc"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="date" label="Finish Date" name="finish_date"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator
                        type="persona"
                        queryPath="projects/collabrators"
                        params={{id}}
                        valField="username"
                        isArray
                        insertItem
                        textField="name"
                        label="Collaborators"
                        name="collabrators"
                    />
                </div>
                <span className="tm-cell">
          <Button onClick={() => App.history.goBack()}>Decline</Button>
        </span>
                <span className="tm-cell">
          <PrimaryButton onClick={this.onSave}>Save</PrimaryButton>
        </span>
            </div>
        );
    }
}

export default reduxForm({form: "task-edit"})(TaskEdit);
