import * as actions from "../actions/index";
import {App} from "../index";
import FineUploaderTraditional from "fine-uploader-wrappers";
import {reloadAllQueries} from "../sagas/api";

export default function uploader_reducer(state = {}, action) {
    switch (action.type) {
        case `${actions.setUploader}`:
            const uploader = new FineUploaderTraditional({
                options: {
                    callbacks: {
                        onComplete: function () {
                            App.actions.pushNotification({
                                type: "success",
                                message: "Files uploaded!"
                            })
                            reloadAllQueries()
                        },
                        onError: function (id, name, errorReason) {
                            console.log(
                                "Error on file number {} - {}.  Reason: {}",
                                id,
                                name,
                                errorReason
                            );
                        }
                    },
                    multiple: true,
                    autoUpload: true,
                    dragAndDrop: {
                        reportDirectoryPaths: true
                    },
                    cors: {
                        expected: true,

                        sendCredentials: true
                    },
                    methods: {
                        addFiles: () => {
                        }
                    },
                    cache: false,
                    request: {
                        params: {
                            "Content-Type": "application/form-data; charset=UTF-8",
                            "Access-Control-Request-Headers": "x-requested-with"
                        },
                        paramsInBody: true,
                        accept: "*/*",
                        endpoint: App.REST_SERVER + "/attachments/upload",
                    },
                    retry: {
                        enableAuto: false
                    }
                }
            });
            return uploader;
        case `${actions.updateUploaderParams}`:
            state.methods.setParams(action.payload);
            return state;
        default:
            return state;
    }
}
