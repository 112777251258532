import * as actions from "../actions/files";

export default function submitted_files(state = [], action) {
    switch (action.type) {
        case `${actions.submitFile}`:
            const newState = [...state];
            const {id} = action.payload;
            newState.push(id);
            return newState;
        case `${actions.deleteFile}`:
            const newFiles = [...state];
            const indexToRemove = newFiles.indexOf(id);
            newFiles.splice(indexToRemove, 1);
            return newState || [];
        default:
            return state;
    }
}
