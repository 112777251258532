import {combineReducers} from "redux";
import form from "redux-form/es/reducer";
import {routerReducer} from "react-router-redux";
import app from "../reducers/app";
import data from "../reducers";
import uploader from "../reducers/uploader";
import submittedFiles from "../reducers/submittedFiles";
import notifications from "../reducers/notification";
import auth from "../reducers/auth";

const rootReducer = combineReducers({
    form,
    auth,
    app,
    data,
    uploader,
    submittedFiles,
    routing: routerReducer,
    notifications
});

export default rootReducer;
