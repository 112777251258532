import {take, fork, call} from "redux-saga/effects";
import * as actions from "../actions/index";
import * as api from "./api";

function* execFetchData(action, getState) {
    const state = getState();
    if (state && state.auth && state.auth.userInfo && state.auth.userInfo.username)
        yield call(api.fetchData, action);
}

function* fetchData(getState) {
    while (true) {
        const action = yield take(`${actions.fetchData}`);
        yield fork(execFetchData, action.payload, getState);
    }
}

function* execReloadQuery(action, getState) {
    const state = getState();
    if (state && state.auth && state.auth.userInfo && state.auth.userInfo.id)
        yield call(api.reloadQuery, action);
}

function* reloadQuery(getState) {
    while (true) {
        const action = yield take(`${actions.reloadQuery}`);
        yield fork(execReloadQuery, action.payload, getState);
    }
}

function* execUpsertForm(action, getState) {
    yield call(api.upsertForm, action, getState());
}

function* upsertForm(getState) {
    while (true) {
        const action = yield take(`${actions.upsertForm}`);
        yield fork(execUpsertForm, action.payload, getState);
    }
}

function* execDeleteRecord(action, getState) {
    yield call(api.deleteRecord, action, getState());
}

function* deleteRecord(getState) {
    while (true) {
        const action = yield take(`${actions.deleteRecord}`);
        yield fork(execDeleteRecord, action.payload, getState);
    }
}

export default function* rootSaga(getState) {
    yield fork(fetchData, getState);
    yield fork(upsertForm, getState);
    yield fork(deleteRecord, getState);
    yield fork(reloadQuery, getState);
}
