import React from "react";
import {Icon} from "office-ui-fabric-react";

const StatusBar = props => {
    const {status} = props;
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                padding: "0 16px",
                color: "white",
                backgroundColor: status ? "#107c10" : "#ff8c00"
            }}
        >
            <div style={{padding: 16}} className={"iota-centered-item"}>
                <Icon
                    styles={{root: {fontSize: 24}}}
                    iconName={status ? "Completed" : "DRM"}
                />
            </div>
            <div>{status ? "Completed" : "On Progress"}</div>
        </div>
    );
};

export default StatusBar;
