import React, {Component} from 'react';
import {Icon, PrimaryButton} from 'office-ui-fabric-react'
import FieldCreator from "../../components/FieldCreator";
import {App} from '../../index';

class Login extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            username: "admin",
            password: '1'
        };
        this.login = this.login.bind(this)
    }

    login() {
        if (this.state.username && this.state.password) {
            App.actions.login(this.state)
        }
    }

    render() {
        return (
            <div>
                <div className="iota-cell">
                    <div style={{margin: "8px 0", padding: "8px 0"}}>
                        <FieldCreator type="shorttext"
                                      onChange={(username) => this.setState({username})}
                                      autoComplete="new-password"
                                      value={this.state.username}
                                      prefix={<Icon iconName="Contact"/>}
                                      placeholder="Username"/>
                    </div>
                    <div style={{margin: "8px 0", padding: "8px 0"}}>
                        <FieldCreator type="password"
                                      autoComplete="new-password"
                                      value={this.state.password}
                                      onChange={(password) => this.setState({password})}
                                      prefix={<Icon iconName="LockSolid"/>}
                                      placeholder={"Password"}/>
                    </div>
                </div>
                <div className="iota-space-between">
                    <PrimaryButton text={"Sign In"}
                                   disabled={!this.state.username || !this.state.password}
                                   style={{width: "100%", margin: 8}}
                                   onClick={this.login}
                    />
                </div>
            </div>
        );
    }
}

export default Login;
