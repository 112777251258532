import React, {Component} from "react";
import FieldCreator from "../../components/FieldCreator";
import {reduxForm} from "redux-form";
import {Button, PrimaryButton} from "office-ui-fabric-react";
import {App} from "../..";
import FormLoader from "../../components-tm/FormLoader";
import connect from "react-redux/es/connect/connect";

class _UserEdit extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
        this.onSave = this.onSave.bind(this);

        const {user_id} = props.match.params;

        App.actions.setTitle({title: user_id ? "User Edit" : "New User"});

        const editPaths = user_id ? [{
            path: "/users/" + user_id,
            label: "User Detail"
        },
            {
                path: "/users/" + user_id + "/edit",
                label: "User Edit"
            }] : [{
            path: "/users/new",
            label: "New User"
        }];

        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/users",
                    label: "User List"
                },
                ...editPaths
            ]
        })

    }

    onSave() {
        const {user_id} = this.props.match.params;
        App.actions.upsertForm({
            formName: "user-edit",
            formPath: "users",
            pk: user_id ? {id: user_id} : undefined,
            onSave: (item) => user_id ? App.history.goBack() : App.history.push("/users/" + item.id)

        });
    }

    render() {
        const {currentUserId} = this.props;
        const {user_id} = this.props.match.params;
        return (
            <div className="tm-card tm-cell">
                <FormLoader
                    formName="user-edit"
                    queryPath={"users"}
                    params={{id: user_id}}
                    isNew={!user_id}
                />
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Username" name="username"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="First Name" name="firstname"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="Last Name" name="lastname"/>
                </div>
                <div className="tm-cell">
                    <FieldCreator type="shorttext" label="E-mail" name="email"/>
                </div>
                {!user_id || currentUserId === 1 ?
                    <div className="tm-cell">
                        <FieldCreator type="shorttext" label="Password" name="password"/>
                    </div> : null}
                <span className="tm-cell">
          <Button onClick={() => App.history.goBack()}>Decline</Button>
        </span>
                <span className="tm-cell">
          <PrimaryButton onClick={this.onSave}>Save</PrimaryButton>
        </span>
            </div>
        );
    }
}

const UserEdit = reduxForm({form: "user-edit"})(
    connect(state => ({
        currentUserId: state.auth && state.auth.userInfo && state.auth.userInfo.id,
        currentUsername: state.auth && state.auth.userInfo && state.auth.userInfo.username
    }))(_UserEdit));
export default UserEdit