import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

export function nestedGet(obj) {
    var args = Array.prototype.slice.call(arguments, 1);
    for (var i = 0; i < args.length; i++) {
        if (!obj)
            return undefined;
        obj = obj[args[i]];
    }
    return obj;
};

class ConnectedList extends React.Component {

    filter(data){
        const {customFilter} = this.props;
        return data.filter((i1,i2)=> !customFilter || customFilter(i1,i2))
    }

    search(data){

        const {searchItem, filterField,caseSensitive} = this.props;

        return data.filter(i => (!searchItem) || !filterField || (caseSensitive ? (filterField.map(i2 => i[i2])).join(" - ").indexOf(searchItem) > -1 : (filterField.map(i2 => (i[i2] + '').turkishToLowerCase())).join(" - ").indexOf(searchItem.turkishToLowerCase()) > -1 ))
    }

    sort(data){
        const {customSort} = this.props;
        return data.sort((i1,i2) => customSort(i1,i2))
    }

    render() {
        const {component, resultSet, loadingComponent,emptyComponent,keyField, useDiv, style, customFilter,className,customSort,searchItem} = this.props;
        
        if(resultSet === undefined){
            if(loadingComponent)
                return <this.props.loadingComponent/>;
            else 
                return null;
        } 
        let data = resultSet || [];

        if(customFilter){
            data = this.filter(data)
        }

        if(searchItem){
            data = this.search(data)
        }

        if(customSort){
            data = this.sort(data)
        }

        if (!data.length) {
            if (emptyComponent)
                return <this.props.emptyComponent/>;
            return null;
        }

        const newProps = {style, className};

        return useDiv ? <div {...newProps}>
                            {data.map((item, index) => <this.props.component item={item} index={index} count={(data && data.length) || undefined} key={keyField ? item[keyField] : index}/>)}
                        </div>
                    :
                        <span {...newProps}>
                            {data.map((item, index) => <this.props.component item={item} index={index} count={(data && data.length) || undefined} key={keyField ? item[keyField] : index}/>)}
                        </span>
    }



    static propTypes = {
        component: PropTypes.func.isRequired,
        emptyComponent: PropTypes.any,
        loadingComponent: PropTypes.any,
        path: PropTypes.array.isRequired,
        keyField: PropTypes.string,
        useDiv: PropTypes.bool.isRequired,
        style: PropTypes.object,
        customFilter: PropTypes.any,
        customSort: PropTypes.any,
        className: PropTypes.string,
        searchItem: PropTypes.string,
        caseSensitive: PropTypes.bool,
        filterField: PropTypes.array,
    };

    static defaultProps = {
        useDiv: false
    };

}

function mapStateToProps(state, ownProps) {
    return {resultSet: nestedGet.apply(null, [state, ...ownProps.path])}
}

export default connect(mapStateToProps)(ConnectedList);
