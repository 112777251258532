import React from "react";
import { Icon } from "office-ui-fabric-react";

const CardTitle = props => {
  const { children, className, style, onClick, icon } = props;
  return (
    <div
      className={className + " iota-card-title ms-font-xl"}
      style={style}
      onClick={onClick}
    >
      <div style={{ display: "flex" }}>
        <div className="ms-font-xl-plus" style={{marginRight: 16}}>
          <Icon iconName={icon} style={{ color: "rgb(16, 110, 190)" }} />
        </div>
        <div style={{ marginTop: 0, width: "100%", overflow: "hidden" }} >
          <p className="ms-fontWeight-semibold ms-font-xl ms-fontSize-l" style={{ margin: 0 }}>
            {children}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardTitle;
