import React from "react";
import {
    List,
    Persona,
    IconButton,
    TextField
} from "office-ui-fabric-react";
import Loading from "../../components/Loading";
import {dateToStr} from "../../utils/utils";
import EmptyComponent from "../../components/EmptyComponent";
import FetchData from "../../components/FetchData";
import {connect} from "react-redux";
import {App} from "../../index";

function scrollToEnd() {
    const commentList = document.getElementById("comment-list");
    commentList.scrollTo(0, commentList.scrollHeight);
}

class _Comments extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {items: undefined, comment: ""};
        this.onCommit = this.onCommit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onCommit() {
        if (!this.state.comment)
            return;
        const {parentType, parentId, myUsername} = this.props;
        App.actions.upsertForm({
            formPath: "comments",
            notification: false,
            values: {
                comment: this.state.comment,
                author: myUsername,
                parent_id: parentId,
                parent_type: parentType,
            },
            onSave: () => {
                App.actions.reloadQuery({
                    queryName: "comments",
                    onFetch: scrollToEnd
                });
                this.setState({comment: ""})
            }
        })
    }

    onChange(e, comment) {
        this.setState({comment})
    }

    render() {
        const {parentId, parentType, myUsername} = this.props;
        return (
            <div>
                <FetchData tableName={"comments"}
                           params={{parent_id: parentId, parent_type: parentType}}/>
                <CommentList myUsername={myUsername}/>
                <div style={{border: "8px solid white", padding: 16}}
                     className="ms-bgColor-neutralLighter">
                    <TextField
                        placeholder="Type a comment..."
                        multiline
                        value={this.state.comment}
                        iconProps={{
                            iconName: "Send",
                            disabled: !this.state.comment,
                            className:
                                "ms-fontColor-themePrimary ms-bgColor-neutralLighter--hover",
                            style: {
                                position: "absolute",
                                right: 0,
                                padding: 8,
                                bottom: 8,
                                borderRadius: 16,
                                fontSize: 20,
                                cursor: "pointer"
                            },
                            onClick: this.onCommit
                        }}
                        onChange={this.onChange}
                        rows={4}
                    />
                </div>
            </div>
        );
    }
}

const Comments = connect(state => ({
    myUsername: state.auth && state.auth.userInfo && state.auth.userInfo.username
}))(_Comments);


class ListRenderer extends React.Component {


    componentDidMount() {
        const commentList = document.getElementById("comment-list");
        setTimeout(() => {
            commentList.scrollTo(0, commentList.scrollHeight);
        }, 100)
    }

    render() {
        const {comments, myUsername} = this.props;
        return <List
            id={"comment-list"}
            items={comments}
            onRenderCell={e => React.createElement(ListItem, {...e, myUsername})}
            style={{maxHeight: 300, overflow: "auto"}}
        />
    }
}

const _CommentList = props => {
    const {comments} = props;
    return comments ? (
        comments.length ? (
            <ListRenderer {...props}/>
        ) : (
            <EmptyComponent dsc={"No Comment"}/>
        )
    ) : (
        <Loading/>
    );
};

const CommentList = connect(state => ({
    comments: state.data && state.data.comments,
}))(_CommentList);

const ListItem = props => {
    const {
        firstname,
        lastname,
        pp_id,
        createdAt,
        author,
        comment,
        email,
        myUsername,
        id
    } = props;
    const isMyComment = author === myUsername;
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: isMyComment ? "flex-end" : "flex-start",
                padding: 8,
                margin: 8
            }}
            data-is-focusable={true}
        >
            <div
                style={{padding: "0 8px", borderRadius: 10, maxWidth: "60%"}}
                className={
                    isMyComment
                        ? "ms-bgColor-themePrimary ms-fontColor-white"
                        : "ms-bgColor-neutralLighter "
                }
            >
                <div style={{display: "flex"}}>
                    {isMyComment && false ? (
                        <IconButton
                            className="ms-bgColor-neutralLighter--hover"
                            iconProps={{iconName: "Delete", style: {color: "red"}}}
                        />
                    ) : null}
                    {!isMyComment ? (
                        <Persona text={firstname ? firstname + " " + lastname : author}
                                 size={3}
                                 imageUrl={App.REST_SERVER + "/attachments/download?id=" + pp_id}
                                 secondaryText={email}
                        />
                    ) : null}
                </div>
                <div
                    className="ms-fontSize-m ms-font-l"
                    style={{
                        padding: 8,
                        margin: 0,
                        display: "flex",
                        alignItems: "flex-end"
                    }}
                >
                    {comment}
                    <div
                        className="ms-fontSize-s ms-font-l ms-fontColor-neutralDark"
                        style={{textAlign: "center", marginLeft: 8}}
                    >
                        {dateToStr(createdAt, "HH:mm")}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comments;
