import React, {Component} from 'react';
import Layout from './pages/Layout';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import Home from './pages/Home'
import {connect} from 'react-redux';
import Loading from "./components/Loading";
import LoginPage from "./pages/LoginPage";
import ProjectList from './pages/Project'
import ProjectDetail from './pages/Project/Detail'
import ProjectEdit from './pages/Project/Edit'
import TaskEdit from './pages/Task/Edit'
import CheckEdit from './pages/Check/Edit'
import CheckDetail from './pages/Check/Detail'
import TaskDetail from './pages/Task/Detail'
import UserList from './pages/User'
import {App} from '.';
import UserEdit from "./pages/User/Edit";
import UserDetail from "./pages/User/Detail";
import StickyBreadCrumb from "./components-tm/AppBreadcrumb";


class _MainPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {auth: {}};
        App.actions.fetchUserInfo({});
    }


    render() {

        const {checked, userInfo} = this.props;
        if (!checked)
            return <div style={{minHeight: "100vh"}} className={"iota-centered-item"}><Loading/></div>;
        return userInfo ? <AppPage/> :
            <Router>
                <div>
                    <Route exact path={"*"} component={LoginPage}/>
                </div>
            </Router>
    }
}

const AppPage = props => {
    App.actions.setUploader();
    return (
        <div>
            <Layout>
                <StickyBreadCrumb/>
                <Router>
                    <div style={{width: "100%"}}>
                        <Switch>
                            <Route exact path={"/"} component={Home}/>
                            <Route exact path={"/projects"} component={ProjectList}/>
                            <Route exact path={"/projects/new"} component={ProjectEdit}/>
                            <Route exact path={"/projects/:id"} component={ProjectDetail}/>
                            <Route exact path={"/projects/:id/newTask"} component={TaskEdit}/>
                            <Route exact path={"/projects/:id/edit"} component={ProjectEdit}/>
                            <Route exact path={"/projects/:id/tasks/:task_id"} component={TaskDetail}/>
                            <Route exact path={"/projects/:id/tasks/:task_id/edit"} component={TaskEdit}/>
                            <Route exact path={"/projects/:id/tasks/:task_id/newCheck"} component={CheckEdit}/>
                            <Route exact path={"/projects/:id/tasks/:task_id/checks/:check_id"} component={CheckDetail}/>
                            <Route exact path={"/projects/:id/tasks/:task_id/checks/:check_id/edit"} component={CheckEdit}/>
                            <Route exact path={"/users"} component={UserList}/>
                            <Route exact path={"/users/new"} component={UserEdit}/>
                            <Route exact path={"/users/:user_id"} component={UserDetail}/>
                            <Route exact path={"/profile"} component={UserDetail}/>
                            <Route exact path={"/profile/edit"} component={UserEdit}/>
                            <Route exact path={"/users/:user_id/edit"} component={UserEdit}/>
                        </Switch>
                    </div>
                </Router>
            </Layout>
        </div>
    )
};

const MainPage = connect(state => ({
    userInfo: state.auth && state.auth.userInfo,
    checked: state.auth && state.auth.checked
}))(_MainPage);

export default MainPage;
