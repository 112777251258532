import React, { Component } from "react";
import FetchData from "../../components/FetchData";
import ConnectedList from "../../components/ConnectedList";
import {
    CommandBar,
    Icon,
} from "office-ui-fabric-react";
import DisplayItem from "../../components/DisplayItem";
import { App } from "../..";
import { connect } from "react-redux";
import DeleteDialog from "../../components-tm/DeleteDialog";
import Protected from "../../components/Protected";
import FileInput from "react-fine-uploader/file-input";

class _UserDetail extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = { deleteRecordId: false }

        const { user_id } = props.match.params;
        App.actions.setTitle({ title: "User Detail" });
        App.actions.setBreadcrumb({
            breadcrumb: [
                {
                    path: "/users",
                    label: "User List"
                },
                {
                    path: "/users/" + user_id,
                    label: "User Detail"
                }
            ]
        })

        this.uploadPhoto = this.uploadPhoto.bind(this);
        if (props.match.params.user_id)
            App.actions.updateUploaderParams({
                parentType: "profile-photo",
                parentId: props.match.params.user_id
            });
    }

    uploadPhoto(id) {
        if (id)
            App.actions.updateUploaderParams({
                parentType: "profile-photo",
                parentId: this.props.match.params.user_id,
                id
            });
        document.getElementById("profile-photo").click();
    }

    render() {
        const { currentUsername, currentUserId } = this.props;
        const { user_id } = this.props.match.params;
        const cmd = [
            {
                key: "edit",
                name: "Edit",
                iconProps: {
                    iconName: "Edit"
                },
                onClick: () => App.history.push(user_id ? "/users/" + user_id + "/edit" : "/profile/edit")
            },
            user_id === 1 ? false : {
                key: "delete",
                name: "Delete",
                iconProps: {
                    iconName: "Delete"
                },
                onClick: () => this.setState({ deleteRecordId: user_id })
            }
        ].filter(i => i);
        return (
            <div className="tm-cell" key={this.state.reload || user_id}>
                <Protected extra={user_info => user_id == user_info.id}>
                    <CommandBar items={cmd} />
                </Protected>
                <DeleteDialog pk={{ id: this.state.deleteRecordId }} open={this.state.deleteRecordId}
                    onClose={() => this.setState({ deleteRecordId: false })}
                    deletePath={"users"}
                    onDelete={() => App.history.push("/users")}
                />
                <FetchData tableName="users" params={{ id: user_id || currentUserId }} />
                <PhotoUpload />
                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <ConnectedList
                                path={["data", "users"]}
                                component={p => {
                                    return (
                                        <div className="tm-card" style={{ padding: 16 }}>
                                            <div className="ms-Grid">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <DisplayItem icon={"Contact"} label={"Username"}>
                                                            {p.item.username}
                                                        </DisplayItem>
                                                        <DisplayItem icon={"ContactInfo"} label={"Fullname"}>
                                                            {p.item.firstname + " " + p.item.lastname.toUpperCase()}
                                                        </DisplayItem>
                                                        <DisplayItem icon={"Mail"} label={"E-mail"}>
                                                            {p.item.email}
                                                        </DisplayItem>
                                                        {p.item.username === currentUsername || currentUserId === 1 ?
                                                            <DisplayItem icon={"LockSolid"} label={"Password"}>
                                                                {p.item.password}
                                                            </DisplayItem> : null}
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm6">
                                                        {p.item.pp_id ?
                                                            <img
                                                                src={App.REST_SERVER + "/attachments/download?id=" + p.item.pp_id + "&dt=" + p.item.updatedAt}
                                                                style={{ cursor: (p.item.username === currentUsername || currentUserId === 1) ? "pointer" : "" }}
                                                                onClick={() => (p.item.username === currentUsername || currentUserId === 1) && this.uploadPhoto(p.item.pp_id)}
                                                                alt="Profile Photo"
                                                                width={"100%"}
                                                                height={"auto"}
                                                            />
                                                            : <div style={{
                                                                padding: 32,
                                                                backgroundColor: "#eee",
                                                                cursor: (p.item.username === currentUsername || currentUserId === 1) ? "pointer" : ""
                                                            }}
                                                                onClick={() => (p.item.username === currentUsername || currentUserId === 1) && this.uploadPhoto()}
                                                                className="iota-centered-item">
                                                                <Icon iconName={"Contact"}
                                                                    styles={{ root: { fontSize: 160, color: "#ccc" } }} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const UserDetail = connect(state => ({
    currentUserId: state.auth && state.auth.userInfo && state.auth.userInfo.id,
    currentUsername: state.auth && state.auth.userInfo && state.auth.userInfo.username
}))(_UserDetail)

const _PhotoUpload = props => {
    const { icon, name, onClick, uploader } = props;
    return (
        <FileInput multiple uploader={uploader} id={"profile-photo"}>
            <span />
        </FileInput>
    );
};

const PhotoUpload = connect(state => ({
    uploader: state.uploader
}))(_PhotoUpload);

export default UserDetail;
