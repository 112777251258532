import * as actions from "../actions/index";

export default function data_reducer(state = {}, action) {
  switch (action.type) {
    case `${actions.fetchDataSuccess}`:
      const { name, data } = action.payload;
      return { ...state, [name]: data };
    case `${actions.clearData}`:
      const { tableName } = action.payload;
      const oldState = { ...state };
      delete oldState[tableName];
      return oldState;
    default:
      return state;
  }
}
