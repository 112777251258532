import * as actions from '../actions/auth';

export default function gui(state = {}, action) {
    switch (action.type) {
        case `${actions.userInfoFetched}`:
            return {checked:true, userInfo: action.payload};
        case `${actions.logout}`:
            return {};
        case `${actions.loggedOut}`:
            return {checked:true};
        default:
            return state;
    }
}