import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

export function nestedGet(obj) {
    var args = Array.prototype.slice.call(arguments, 1);
    for (var i = 0; i < args.length; i++) {
        if (!obj)
            return undefined;
        obj = obj[args[i]];
    }
    return obj;
};

class ConnectedItem extends React.Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.resultSet != nextProps.resultSet;
    }

    render() {
        const {component, resultSet, loadingComponent, emptyComponent} = this.props;

        if (resultSet === undefined) {
            if (loadingComponent)
                return <this.props.loadingComponent/>;
            else
                return null;
        }
        let data = resultSet || {};

        if (!Object.keys(data).length) {
            if (emptyComponent)
                return <this.props.emptyComponent/>;
            return null;
        }

        return <this.props.component item={data}/>
    }


    static propTypes = {
        component: PropTypes.func.isRequired,
        emptyComponent: PropTypes.any,
        loadingComponent: PropTypes.any,
        path: PropTypes.array.isRequired,
        keyField: PropTypes.string,
        useDiv: PropTypes.bool.isRequired,
        style: PropTypes.object,
        customFilter: PropTypes.any,
        customSort: PropTypes.any,
        className: PropTypes.string,
        searchItem: PropTypes.string,
        caseSensitive: PropTypes.bool,
        filterField: PropTypes.array,
    };

    static defaultProps = {
        useDiv: false
    };

}

function mapStateToProps(state, ownProps) {
    return {resultSet: nestedGet.apply(null, [state, ...ownProps.path])}
}

export default connect(mapStateToProps)(ConnectedItem);
